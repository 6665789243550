/* tslint:disable */
/* eslint-disable */
/**
 * CrefoTrust API - Core Service API
 * Full version of the API of the CrefoTrust core-services, for docs see https://docs.crefotrust.de
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface AcceptOrderDto
 */
export interface AcceptOrderDto {
    /**
     * 
     * @type {string}
     * @memberof AcceptOrderDto
     */
    'userCrefoId': string;
    /**
     * 
     * @type {string}
     * @memberof AcceptOrderDto
     */
    'comment'?: string;
}
/**
 * 
 * @export
 * @interface Account
 */
export interface Account {
    /**
     * 
     * @type {string}
     * @memberof Account
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof Account
     */
    'did': string;
    /**
     * 
     * @type {string}
     * @memberof Account
     */
    'type': string;
    /**
     * 
     * @type {string}
     * @memberof Account
     */
    'auth': string;
    /**
     * 
     * @type {string}
     * @memberof Account
     */
    'username': string;
    /**
     * 
     * @type {string}
     * @memberof Account
     */
    'verified': string;
    /**
     * 
     * @type {string}
     * @memberof Account
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof Account
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof Account
     */
    'createdAt': number;
    /**
     * 
     * @type {number}
     * @memberof Account
     */
    'updatedAt': number;
}
/**
 * 
 * @export
 * @interface Address
 */
export interface Address {
    /**
     * Street and number.
     * @type {string}
     * @memberof Address
     */
    'streetAddress': string;
    /**
     * Postal code of the city.
     * @type {string}
     * @memberof Address
     */
    'postalCode': string;
    /**
     * Name of the city.
     * @type {string}
     * @memberof Address
     */
    'addressLocality': string;
    /**
     * ISO code of the country.
     * @type {string}
     * @memberof Address
     */
    'addressCountry': string;
}
/**
 * 
 * @export
 * @interface AddressDto
 */
export interface AddressDto {
    /**
     * 
     * @type {string}
     * @memberof AddressDto
     */
    'streetAddress': string;
    /**
     * 
     * @type {string}
     * @memberof AddressDto
     */
    'postalCode': string;
    /**
     * 
     * @type {string}
     * @memberof AddressDto
     */
    'addressLocality': string;
    /**
     * 
     * @type {string}
     * @memberof AddressDto
     */
    'addressCountry': string;
}
/**
 * 
 * @export
 * @interface AdminAccountDto
 */
export interface AdminAccountDto {
    /**
     * 
     * @type {Account}
     * @memberof AdminAccountDto
     */
    'account': Account;
    /**
     * 
     * @type {Array<Credential>}
     * @memberof AdminAccountDto
     */
    'credentials': Array<Credential>;
    /**
     * 
     * @type {Person}
     * @memberof AdminAccountDto
     */
    'person': Person;
    /**
     * 
     * @type {string}
     * @memberof AdminAccountDto
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof AdminAccountDto
     */
    'updatedAt': string;
}
/**
 * 
 * @export
 * @interface AdminAccountsResponse
 */
export interface AdminAccountsResponse {
    /**
     * 
     * @type {number}
     * @memberof AdminAccountsResponse
     */
    'count': number;
    /**
     * 
     * @type {number}
     * @memberof AdminAccountsResponse
     */
    'totalCount': number;
    /**
     * 
     * @type {Array<AdminAccountDto>}
     * @memberof AdminAccountsResponse
     */
    'data': Array<AdminAccountDto>;
}
/**
 * 
 * @export
 * @interface AdminCompaniesResponse
 */
export interface AdminCompaniesResponse {
    /**
     * 
     * @type {number}
     * @memberof AdminCompaniesResponse
     */
    'count': number;
    /**
     * 
     * @type {number}
     * @memberof AdminCompaniesResponse
     */
    'totalCount': number;
    /**
     * 
     * @type {Array<AdminCompanyDto>}
     * @memberof AdminCompaniesResponse
     */
    'data': Array<AdminCompanyDto>;
}
/**
 * 
 * @export
 * @interface AdminCompanyDto
 */
export interface AdminCompanyDto {
    /**
     * 
     * @type {Array<Account>}
     * @memberof AdminCompanyDto
     */
    'accounts': Array<Account>;
    /**
     * 
     * @type {Array<Company>}
     * @memberof AdminCompanyDto
     */
    'company': Array<Company>;
    /**
     * 
     * @type {string}
     * @memberof AdminCompanyDto
     */
    'did': string;
    /**
     * 
     * @type {string}
     * @memberof AdminCompanyDto
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof AdminCompanyDto
     */
    'updatedAt': string;
}
/**
 * 
 * @export
 * @interface AdminVerifiersResponse
 */
export interface AdminVerifiersResponse {
    /**
     * 
     * @type {number}
     * @memberof AdminVerifiersResponse
     */
    'count': number;
    /**
     * 
     * @type {number}
     * @memberof AdminVerifiersResponse
     */
    'totalCount': number;
    /**
     * 
     * @type {Array<Verifier>}
     * @memberof AdminVerifiersResponse
     */
    'data': Array<Verifier>;
}
/**
 * 
 * @export
 * @interface AuthConfig
 */
export interface AuthConfig {
    /**
     * Decides which SSO to use
     * @type {string}
     * @memberof AuthConfig
     */
    'prk'?: string;
    /**
     * base url of auth provider: e.g. keycloak-inv
     * @type {string}
     * @memberof AuthConfig
     */
    'redirectUri'?: string;
    /**
     * base url of auth provider: e.g. keycloak-inv
     * @type {string}
     * @memberof AuthConfig
     */
    'loginUri'?: string;
    /**
     * base url of auth provider: e.g. keycloak-inv
     * @type {string}
     * @memberof AuthConfig
     */
    'registrationUri'?: string;
    /**
     * TODO: add detailed description what clientId is used for
     * @type {string}
     * @memberof AuthConfig
     */
    'protocol'?: string;
    /**
     * TODO: add detailed description what clientId is used for
     * @type {string}
     * @memberof AuthConfig
     */
    'clientId'?: string;
    /**
     * Contains the title displayed in the SSO view
     * @type {string}
     * @memberof AuthConfig
     */
    'title'?: string;
    /**
     * 
     * @type {string}
     * @memberof AuthConfig
     */
    'realm'?: string;
}
/**
 * 
 * @export
 * @interface AuthorizedRepresentativeMeta
 */
export interface AuthorizedRepresentativeMeta {
    /**
     * Timestamp (unix epoch) when this information was created.
     * @type {number}
     * @memberof AuthorizedRepresentativeMeta
     */
    'issuedAt': number;
    /**
     * The user (DID) to that this information regards to.
     * @type {string}
     * @memberof AuthorizedRepresentativeMeta
     */
    'person': string;
    /**
     * The company (DID) to that this information regards to.
     * @type {string}
     * @memberof AuthorizedRepresentativeMeta
     */
    'org': string;
    /**
     * This endpoint (url) provided by CrefoTrust can be used to check the current staus of the provided information.
     * @type {string}
     * @memberof AuthorizedRepresentativeMeta
     */
    'status': string;
    /**
     * Issuer (DID) of the credential data.
     * @type {string}
     * @memberof AuthorizedRepresentativeMeta
     */
    'issuer': string;
}
/**
 * 
 * @export
 * @interface Company
 */
export interface Company {
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    'legalName': string;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    'crefoId': string;
    /**
     * 
     * @type {CrefoAddress}
     * @memberof Company
     */
    'address': CrefoAddress;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    'legalForm': string;
}
/**
 * 
 * @export
 * @interface CompanyAgentMeta
 */
export interface CompanyAgentMeta {
    /**
     * Timestamp (unix epoch) when this information was created.
     * @type {number}
     * @memberof CompanyAgentMeta
     */
    'issuedAt': number;
    /**
     * The user (DID) to that this information regards to.
     * @type {string}
     * @memberof CompanyAgentMeta
     */
    'person': string;
    /**
     * The company (DID) to that this information regards to.
     * @type {string}
     * @memberof CompanyAgentMeta
     */
    'org': string;
    /**
     * This endpoint (url) provided by CrefoTrust can be used to check the current staus of the provided information.
     * @type {string}
     * @memberof CompanyAgentMeta
     */
    'status': string;
    /**
     * Issuer (DID) of the credential data.
     * @type {string}
     * @memberof CompanyAgentMeta
     */
    'issuer': string;
}
/**
 * 
 * @export
 * @interface CompanyDto
 */
export interface CompanyDto {
    /**
     * 
     * @type {string}
     * @memberof CompanyDto
     */
    'legalName': string;
    /**
     * 
     * @type {string}
     * @memberof CompanyDto
     */
    'crefoId': string;
    /**
     * 
     * @type {AddressDto}
     * @memberof CompanyDto
     */
    'address': AddressDto;
}
/**
 * 
 * @export
 * @interface ComparisonResultObject
 */
export interface ComparisonResultObject {
    /**
     * 
     * @type {string}
     * @memberof ComparisonResultObject
     */
    'name': ComparisonResultObjectNameEnum;
    /**
     * 
     * @type {object}
     * @memberof ComparisonResultObject
     */
    'compared': object;
    /**
     * 
     * @type {string}
     * @memberof ComparisonResultObject
     */
    'key': string;
    /**
     * 
     * @type {boolean}
     * @memberof ComparisonResultObject
     */
    'isMatching': boolean;
}

export const ComparisonResultObjectNameEnum = {
    GivenNameMatches: 'GIVEN_NAME_MATCHES',
    FirstGivenNameMatches: 'FIRST_GIVEN_NAME_MATCHES',
    AnyGivenNameMatches: 'ANY_GIVEN_NAME_MATCHES',
    FamilyNameMatches: 'FAMILY_NAME_MATCHES',
    PostalCodeMatches: 'POSTAL_CODE_MATCHES',
    BirthdayMatches: 'BIRTHDAY_MATCHES'
} as const;

export type ComparisonResultObjectNameEnum = typeof ComparisonResultObjectNameEnum[keyof typeof ComparisonResultObjectNameEnum];

/**
 * 
 * @export
 * @interface Context
 */
export interface Context {
    /**
     * 
     * @type {string}
     * @memberof Context
     */
    'did': string;
    /**
     * 
     * @type {string}
     * @memberof Context
     */
    'orderId': string;
    /**
     * 
     * @type {string}
     * @memberof Context
     */
    'flavor': ContextFlavorEnum;
}

export const ContextFlavorEnum = {
    B2B: 'B2B',
    B2C: 'B2C'
} as const;

export type ContextFlavorEnum = typeof ContextFlavorEnum[keyof typeof ContextFlavorEnum];

/**
 * 
 * @export
 * @interface ContextPayloadDto
 */
export interface ContextPayloadDto {
    /**
     * 
     * @type {IdentRequestDto}
     * @memberof ContextPayloadDto
     */
    'identRequest'?: IdentRequestDto;
    /**
     * 
     * @type {object}
     * @memberof ContextPayloadDto
     */
    'customerProvidedData'?: object;
    /**
     * 
     * @type {string}
     * @memberof ContextPayloadDto
     */
    'affiliation'?: ContextPayloadDtoAffiliationEnum;
}

export const ContextPayloadDtoAffiliationEnum = {
    Person: 'PERSON',
    Company: 'COMPANY'
} as const;

export type ContextPayloadDtoAffiliationEnum = typeof ContextPayloadDtoAffiliationEnum[keyof typeof ContextPayloadDtoAffiliationEnum];

/**
 * 
 * @export
 * @interface CreateAuthRepEmailDto
 */
export interface CreateAuthRepEmailDto {
    /**
     * 
     * @type {EmailPersonDto}
     * @memberof CreateAuthRepEmailDto
     */
    'issuer': EmailPersonDto;
    /**
     * 
     * @type {EmailPersonDto}
     * @memberof CreateAuthRepEmailDto
     */
    'recipient': EmailPersonDto;
    /**
     * 
     * @type {OrganisationDto}
     * @memberof CreateAuthRepEmailDto
     */
    'organisation': OrganisationDto;
    /**
     * 
     * @type {string}
     * @memberof CreateAuthRepEmailDto
     */
    'useCase': string;
}
/**
 * 
 * @export
 * @interface CreateOrderDto
 */
export interface CreateOrderDto {
    /**
     * 
     * @type {string}
     * @memberof CreateOrderDto
     */
    'verifierId': string;
    /**
     * 
     * @type {RequiredCredentials}
     * @memberof CreateOrderDto
     */
    'requiredCredentials': RequiredCredentials;
    /**
     * Webhook triggered after completion. See VerifierWebhookResponse below for the response payload structure. Order-id will be appended.
     * @type {string}
     * @memberof CreateOrderDto
     */
    'webhook': string;
    /**
     * 
     * @type {OrderRedirect}
     * @memberof CreateOrderDto
     */
    'redirect': OrderRedirect;
    /**
     * 
     * @type {OrderOptions}
     * @memberof CreateOrderDto
     */
    'options'?: OrderOptions;
    /**
     * Defines all ident methods, that the verifier accepts for its\' holders identification. Methods which are accepted do not necessarily have to be offered.
     * @type {Array<string>}
     * @memberof CreateOrderDto
     */
    'acceptedIdMethods'?: Array<CreateOrderDtoAcceptedIdMethodsEnum>;
    /**
     * Defines all ident methods, that the verifier wants to offer its\' holders for identification. All methods which are offered must be accepted.
     * @type {Array<string>}
     * @memberof CreateOrderDto
     */
    'offeredIdMethods'?: Array<CreateOrderDtoOfferedIdMethodsEnum>;
    /**
     * 
     * @type {OrderPreset}
     * @memberof CreateOrderDto
     */
    'forOrg'?: OrderPreset;
    /**
     * 
     * @type {CreateOrderDtoForPerson}
     * @memberof CreateOrderDto
     */
    'forPerson'?: CreateOrderDtoForPerson;
    /**
     * 
     * @type {CreateOrderDtoAuth}
     * @memberof CreateOrderDto
     */
    'auth'?: CreateOrderDtoAuth;
    /**
     * 
     * @type {string}
     * @memberof CreateOrderDto
     */
    'selectedIdentProvider'?: CreateOrderDtoSelectedIdentProviderEnum;
}

export const CreateOrderDtoAcceptedIdMethodsEnum = {
    ItmVideoident: 'itm_videoident',
    NectAutoident: 'nect_autoident',
    PinletterIdent: 'pinletter_ident'
} as const;

export type CreateOrderDtoAcceptedIdMethodsEnum = typeof CreateOrderDtoAcceptedIdMethodsEnum[keyof typeof CreateOrderDtoAcceptedIdMethodsEnum];
export const CreateOrderDtoOfferedIdMethodsEnum = {
    ItmVideoident: 'itm_videoident',
    NectAutoident: 'nect_autoident',
    PinletterIdent: 'pinletter_ident'
} as const;

export type CreateOrderDtoOfferedIdMethodsEnum = typeof CreateOrderDtoOfferedIdMethodsEnum[keyof typeof CreateOrderDtoOfferedIdMethodsEnum];
export const CreateOrderDtoSelectedIdentProviderEnum = {
    CrefoFirmenwissen: 'crefo_firmenwissen',
    Admin: 'admin',
    ItmVideoident: 'itm_videoident',
    NectAutoident: 'nect_autoident',
    PinletterIdent: 'pinletter_ident',
    Did: 'did'
} as const;

export type CreateOrderDtoSelectedIdentProviderEnum = typeof CreateOrderDtoSelectedIdentProviderEnum[keyof typeof CreateOrderDtoSelectedIdentProviderEnum];

/**
 * Configuration used to authenticate the holders account.
 * @export
 * @interface CreateOrderDtoAuth
 */
export interface CreateOrderDtoAuth {
    /**
     * Decides which SSO to use
     * @type {string}
     * @memberof CreateOrderDtoAuth
     */
    'prk'?: string;
    /**
     * base url of auth provider: e.g. keycloak-inv
     * @type {string}
     * @memberof CreateOrderDtoAuth
     */
    'redirectUri'?: string;
    /**
     * base url of auth provider: e.g. keycloak-inv
     * @type {string}
     * @memberof CreateOrderDtoAuth
     */
    'loginUri'?: string;
    /**
     * base url of auth provider: e.g. keycloak-inv
     * @type {string}
     * @memberof CreateOrderDtoAuth
     */
    'registrationUri'?: string;
    /**
     * TODO: add detailed description what clientId is used for
     * @type {string}
     * @memberof CreateOrderDtoAuth
     */
    'protocol'?: string;
    /**
     * TODO: add detailed description what clientId is used for
     * @type {string}
     * @memberof CreateOrderDtoAuth
     */
    'clientId'?: string;
    /**
     * Contains the title displayed in the SSO view
     * @type {string}
     * @memberof CreateOrderDtoAuth
     */
    'title'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateOrderDtoAuth
     */
    'realm'?: string;
}
/**
 * Can only be used in combination with \"forOrg\". Marking this as \"mandatory\" requires \"forOrg\" to be also set to \"mandatory\".
 * @export
 * @interface CreateOrderDtoForPerson
 */
export interface CreateOrderDtoForPerson {
    /**
     * An order preset set to mandatory forces the holder to verify for the provided crefoId.
     * @type {boolean}
     * @memberof CreateOrderDtoForPerson
     */
    'mandatory': boolean;
    /**
     * Defines the person / company the holder should be verify for / as.
     * @type {string}
     * @memberof CreateOrderDtoForPerson
     */
    'crefoId': string;
    /**
     * 
     * @type {string}
     * @memberof CreateOrderDtoForPerson
     */
    'did'?: string;
}
/**
 * 
 * @export
 * @interface CreatePinLetterDto
 */
export interface CreatePinLetterDto {
    /**
     * 
     * @type {string}
     * @memberof CreatePinLetterDto
     */
    'useCase'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreatePinLetterDto
     */
    'crefoId': string;
    /**
     * 
     * @type {string}
     * @memberof CreatePinLetterDto
     */
    'verifierId': string;
    /**
     * 
     * @type {AddressDto}
     * @memberof CreatePinLetterDto
     */
    'address': AddressDto;
}
/**
 * 
 * @export
 * @interface CreateVerifierDto
 */
export interface CreateVerifierDto {
    /**
     * 
     * @type {string}
     * @memberof CreateVerifierDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CreateVerifierDto
     */
    'customHeaderKey'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateVerifierDto
     */
    'customHeaderValue'?: string;
    /**
     * 
     * @type {object}
     * @memberof CreateVerifierDto
     */
    'dataEnabler'?: object;
    /**
     * 
     * @type {string}
     * @memberof CreateVerifierDto
     */
    'logoUrl'?: string;
}
/**
 * 
 * @export
 * @interface Credential
 */
export interface Credential {
    /**
     * 
     * @type {string}
     * @memberof Credential
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof Credential
     */
    'type': CredentialTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof Credential
     */
    'did': string;
    /**
     * 
     * @type {string}
     * @memberof Credential
     */
    'dataSource': CredentialDataSourceEnum;
    /**
     * 
     * @type {object}
     * @memberof Credential
     */
    'payload': object;
    /**
     * 
     * @type {string}
     * @memberof Credential
     */
    'jwt': string;
    /**
     * 
     * @type {string}
     * @memberof Credential
     */
    'status': CredentialStatusEnum;
    /**
     * 
     * @type {number}
     * @memberof Credential
     */
    'createdAt': number;
    /**
     * 
     * @type {number}
     * @memberof Credential
     */
    'updatedAt': number;
    /**
     * 
     * @type {object}
     * @memberof Credential
     */
    'dataEnabler'?: object;
    /**
     * 
     * @type {string}
     * @memberof Credential
     */
    'reason'?: string;
}

export const CredentialTypeEnum = {
    Crid: 'crid',
    Name: 'name',
    Address: 'address',
    Nationality: 'nationality',
    Birthdate: 'birthdate',
    CrefoId: 'crefoId',
    LegalName: 'legalName',
    LegalForm: 'legalForm',
    AuthorizedRepresentative: 'authorizedRepresentative',
    CompanyAgent: 'companyAgent'
} as const;

export type CredentialTypeEnum = typeof CredentialTypeEnum[keyof typeof CredentialTypeEnum];
export const CredentialDataSourceEnum = {
    CrefoFirmenwissen: 'crefo_firmenwissen',
    Admin: 'admin',
    ItmVideoident: 'itm_videoident',
    NectAutoident: 'nect_autoident',
    PinletterIdent: 'pinletter_ident',
    Did: 'did'
} as const;

export type CredentialDataSourceEnum = typeof CredentialDataSourceEnum[keyof typeof CredentialDataSourceEnum];
export const CredentialStatusEnum = {
    Valid: 'VALID',
    Revoked: 'REVOKED',
    Expired: 'EXPIRED',
    NotFound: 'NOT_FOUND'
} as const;

export type CredentialStatusEnum = typeof CredentialStatusEnum[keyof typeof CredentialStatusEnum];

/**
 * 
 * @export
 * @interface CredentialState
 */
export interface CredentialState {
    /**
     * 
     * @type {boolean}
     * @memberof CredentialState
     */
    'fulfilled': boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof CredentialState
     */
    'missingCredentials': Array<string>;
}
/**
 * 
 * @export
 * @interface CrefoAddress
 */
export interface CrefoAddress {
    /**
     * 
     * @type {string}
     * @memberof CrefoAddress
     */
    'streetAddress': string;
    /**
     * 
     * @type {string}
     * @memberof CrefoAddress
     */
    'postalCode': string;
    /**
     * 
     * @type {string}
     * @memberof CrefoAddress
     */
    'addressLocality': string;
    /**
     * 
     * @type {string}
     * @memberof CrefoAddress
     */
    'addressCountry': string;
}
/**
 * 
 * @export
 * @interface CrefoIdentOrderLink
 */
export interface CrefoIdentOrderLink {
    /**
     * 
     * @type {string}
     * @memberof CrefoIdentOrderLink
     */
    'OrderID': string;
    /**
     * 
     * @type {string}
     * @memberof CrefoIdentOrderLink
     */
    'TargetURL': string;
    /**
     * 
     * @type {string}
     * @memberof CrefoIdentOrderLink
     */
    'VideoTargetLink'?: string;
    /**
     * 
     * @type {string}
     * @memberof CrefoIdentOrderLink
     */
    'Ref'?: string;
}
/**
 * 
 * @export
 * @interface EmailPersonDto
 */
export interface EmailPersonDto {
    /**
     * 
     * @type {string}
     * @memberof EmailPersonDto
     */
    'givenName': string;
    /**
     * 
     * @type {string}
     * @memberof EmailPersonDto
     */
    'familyName': string;
    /**
     * 
     * @type {string}
     * @memberof EmailPersonDto
     */
    'email'?: string;
}
/**
 * 
 * @export
 * @interface ExtOrderResponse
 */
export interface ExtOrderResponse {
    /**
     * 
     * @type {string}
     * @memberof ExtOrderResponse
     */
    'id': string;
    /**
     * The verifier\'s id used for uniquely identifying the verifier.
     * @type {string}
     * @memberof ExtOrderResponse
     */
    'verifierId': string;
    /**
     * 
     * @type {RequiredCredentials}
     * @memberof ExtOrderResponse
     */
    'requiredCredentials': RequiredCredentials;
    /**
     * Webhook triggered after completion. See VerifierWebhookResponse below for the reponse payload structure. Order-id will be appended.
     * @type {string}
     * @memberof ExtOrderResponse
     */
    'webhook': string;
    /**
     * 
     * @type {OrderRedirect}
     * @memberof ExtOrderResponse
     */
    'redirect': OrderRedirect;
    /**
     * 
     * @type {Array<string>}
     * @memberof ExtOrderResponse
     */
    'state': Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof ExtOrderResponse
     */
    'completed': boolean;
    /**
     * 
     * @type {OrderOptions}
     * @memberof ExtOrderResponse
     */
    'options': OrderOptions;
    /**
     * 
     * @type {OrderPreset}
     * @memberof ExtOrderResponse
     */
    'forOrg': OrderPreset;
    /**
     * 
     * @type {OrderPreset}
     * @memberof ExtOrderResponse
     */
    'forPerson': OrderPreset;
    /**
     * Defines all ident methods, that the verifier accepts for its\' holders identification. Methods which are accepted do not necessarily have to be offered.
     * @type {Array<string>}
     * @memberof ExtOrderResponse
     */
    'acceptedIdMethods'?: Array<ExtOrderResponseAcceptedIdMethodsEnum>;
    /**
     * Defines all ident methods, that the verifier wants to offer its\' holders for identification. All methods which are offered must be accepted.
     * @type {Array<string>}
     * @memberof ExtOrderResponse
     */
    'offeredIdMethods'?: Array<ExtOrderResponseOfferedIdMethodsEnum>;
    /**
     * An arbitrary data structure provided by the verifier while creating the order.
     * @type {object}
     * @memberof ExtOrderResponse
     */
    'verifierRef'?: object;
    /**
     * 
     * @type {OrderAuthRedirects}
     * @memberof ExtOrderResponse
     */
    'redirects': OrderAuthRedirects;
    /**
     * 
     * @type {string}
     * @memberof ExtOrderResponse
     */
    'crefoId': string;
    /**
     * 
     * @type {string}
     * @memberof ExtOrderResponse
     */
    'verifierName': string;
    /**
     * 
     * @type {Person}
     * @memberof ExtOrderResponse
     */
    'person'?: Person;
    /**
     * 
     * @type {Company}
     * @memberof ExtOrderResponse
     */
    'company'?: Company;
    /**
     * 
     * @type {string}
     * @memberof ExtOrderResponse
     */
    'selectedIdentProvider'?: ExtOrderResponseSelectedIdentProviderEnum;
    /**
     * 
     * @type {PersonMatchError}
     * @memberof ExtOrderResponse
     */
    'personMatchErrorLog'?: PersonMatchError;
}

export const ExtOrderResponseAcceptedIdMethodsEnum = {
    ItmVideoident: 'itm_videoident',
    NectAutoident: 'nect_autoident'
} as const;

export type ExtOrderResponseAcceptedIdMethodsEnum = typeof ExtOrderResponseAcceptedIdMethodsEnum[keyof typeof ExtOrderResponseAcceptedIdMethodsEnum];
export const ExtOrderResponseOfferedIdMethodsEnum = {
    ItmVideoident: 'itm_videoident',
    NectAutoident: 'nect_autoident'
} as const;

export type ExtOrderResponseOfferedIdMethodsEnum = typeof ExtOrderResponseOfferedIdMethodsEnum[keyof typeof ExtOrderResponseOfferedIdMethodsEnum];
export const ExtOrderResponseSelectedIdentProviderEnum = {
    CrefoFirmenwissen: 'crefo_firmenwissen',
    Admin: 'admin',
    ItmVideoident: 'itm_videoident',
    NectAutoident: 'nect_autoident',
    PinletterIdent: 'pinletter_ident',
    Did: 'did'
} as const;

export type ExtOrderResponseSelectedIdentProviderEnum = typeof ExtOrderResponseSelectedIdentProviderEnum[keyof typeof ExtOrderResponseSelectedIdentProviderEnum];

/**
 * 
 * @export
 * @interface ExtendedOrder
 */
export interface ExtendedOrder {
    /**
     * 
     * @type {string}
     * @memberof ExtendedOrder
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ExtendedOrder
     */
    'verifierId'?: string;
    /**
     * 
     * @type {RequiredCredentials}
     * @memberof ExtendedOrder
     */
    'requiredCredentials'?: RequiredCredentials;
    /**
     * 
     * @type {string}
     * @memberof ExtendedOrder
     */
    'webhook'?: string;
    /**
     * 
     * @type {OrderRedirect}
     * @memberof ExtendedOrder
     */
    'redirect'?: OrderRedirect;
    /**
     * 
     * @type {IDMethods}
     * @memberof ExtendedOrder
     */
    'idMethods'?: IDMethods;
    /**
     * 
     * @type {Verifier}
     * @memberof ExtendedOrder
     */
    'verifier'?: Verifier;
    /**
     * 
     * @type {Account}
     * @memberof ExtendedOrder
     */
    'account'?: Account;
    /**
     * 
     * @type {string}
     * @memberof ExtendedOrder
     */
    'selectedIdentProvider'?: ExtendedOrderSelectedIdentProviderEnum;
    /**
     * 
     * @type {Person}
     * @memberof ExtendedOrder
     */
    'person'?: Person;
    /**
     * 
     * @type {Company}
     * @memberof ExtendedOrder
     */
    'company'?: Company;
    /**
     * 
     * @type {PersonMatchError}
     * @memberof ExtendedOrder
     */
    'personMatchErrorLog'?: PersonMatchError;
    /**
     * 
     * @type {Array<OrderStep>}
     * @memberof ExtendedOrder
     */
    'state'?: Array<OrderStep>;
    /**
     * 
     * @type {OrderStep}
     * @memberof ExtendedOrder
     */
    'currentState'?: OrderStep;
    /**
     * 
     * @type {boolean}
     * @memberof ExtendedOrder
     */
    'completed'?: boolean;
    /**
     * 
     * @type {OrderOptions}
     * @memberof ExtendedOrder
     */
    'options'?: OrderOptions;
    /**
     * 
     * @type {OrderPreset}
     * @memberof ExtendedOrder
     */
    'forOrg'?: OrderPreset;
    /**
     * 
     * @type {OrderPreset}
     * @memberof ExtendedOrder
     */
    'forPerson'?: OrderPreset;
    /**
     * 
     * @type {AuthConfig}
     * @memberof ExtendedOrder
     */
    'auth'?: AuthConfig;
    /**
     * 
     * @type {string}
     * @memberof ExtendedOrder
     */
    'contextFlavor'?: ExtendedOrderContextFlavorEnum;
    /**
     * 
     * @type {Array<string>}
     * @memberof ExtendedOrder
     */
    'pendingPersonRequests'?: Array<string>;
    /**
     * 
     * @type {Array<Credential>}
     * @memberof ExtendedOrder
     */
    'credentials'?: Array<Credential>;
    /**
     * 
     * @type {Array<PersonRequest>}
     * @memberof ExtendedOrder
     */
    'personRequests'?: Array<PersonRequest>;
    /**
     * 
     * @type {object}
     * @memberof ExtendedOrder
     */
    'identData'?: object;
    /**
     * 
     * @type {string}
     * @memberof ExtendedOrder
     */
    'createdAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof ExtendedOrder
     */
    'updatedAt'?: string;
}

export const ExtendedOrderSelectedIdentProviderEnum = {
    CrefoFirmenwissen: 'crefo_firmenwissen',
    Admin: 'admin',
    ItmVideoident: 'itm_videoident',
    NectAutoident: 'nect_autoident',
    PinletterIdent: 'pinletter_ident',
    Did: 'did'
} as const;

export type ExtendedOrderSelectedIdentProviderEnum = typeof ExtendedOrderSelectedIdentProviderEnum[keyof typeof ExtendedOrderSelectedIdentProviderEnum];
export const ExtendedOrderContextFlavorEnum = {
    B2B: 'B2B',
    B2C: 'B2C'
} as const;

export type ExtendedOrderContextFlavorEnum = typeof ExtendedOrderContextFlavorEnum[keyof typeof ExtendedOrderContextFlavorEnum];

/**
 * 
 * @export
 * @interface ExtendedOrderResponse
 */
export interface ExtendedOrderResponse {
    /**
     * 
     * @type {number}
     * @memberof ExtendedOrderResponse
     */
    'count': number;
    /**
     * 
     * @type {number}
     * @memberof ExtendedOrderResponse
     */
    'totalCount': number;
    /**
     * 
     * @type {Array<ExtendedOrder>}
     * @memberof ExtendedOrderResponse
     */
    'data': Array<ExtendedOrder>;
}
/**
 * 
 * @export
 * @interface HolderCredentialState
 */
export interface HolderCredentialState {
    /**
     * 
     * @type {CredentialState}
     * @memberof HolderCredentialState
     */
    'personCredentialState': CredentialState;
    /**
     * 
     * @type {CredentialState}
     * @memberof HolderCredentialState
     */
    'companyCredentialState': CredentialState;
    /**
     * 
     * @type {CredentialState}
     * @memberof HolderCredentialState
     */
    'representationCredentialState': CredentialState;
}
/**
 * 
 * @export
 * @interface HolderState
 */
export interface HolderState {
    /**
     * 
     * @type {HolderStateData}
     * @memberof HolderState
     */
    'data': HolderStateData;
    /**
     * 
     * @type {Context}
     * @memberof HolderState
     */
    'context': Context;
    /**
     * 
     * @type {HolderStateMeta}
     * @memberof HolderState
     */
    'meta': HolderStateMeta;
    /**
     * 
     * @type {RoutingState}
     * @memberof HolderState
     */
    'routing': RoutingState;
    /**
     * 
     * @type {HolderStateActions}
     * @memberof HolderState
     */
    'actions'?: HolderStateActions;
}
/**
 * 
 * @export
 * @interface HolderStateActions
 */
export interface HolderStateActions {
    /**
     * 
     * @type {Array<string>}
     * @memberof HolderStateActions
     */
    'customerRequiredData': Array<HolderStateActionsCustomerRequiredDataEnum>;
}

export const HolderStateActionsCustomerRequiredDataEnum = {
    Crid: 'crid',
    Name: 'name',
    Address: 'address',
    Nationality: 'nationality',
    Birthdate: 'birthdate',
    CrefoId: 'crefoId',
    LegalName: 'legalName',
    LegalForm: 'legalForm',
    AuthorizedRepresentative: 'authorizedRepresentative',
    CompanyAgent: 'companyAgent'
} as const;

export type HolderStateActionsCustomerRequiredDataEnum = typeof HolderStateActionsCustomerRequiredDataEnum[keyof typeof HolderStateActionsCustomerRequiredDataEnum];

/**
 * 
 * @export
 * @interface HolderStateData
 */
export interface HolderStateData {
    /**
     * 
     * @type {Array<Credential>}
     * @memberof HolderStateData
     */
    'personCredentials': Array<Credential>;
    /**
     * 
     * @type {Array<Credential>}
     * @memberof HolderStateData
     */
    'companyCredentials': Array<Credential>;
    /**
     * 
     * @type {Array<PersonRequest>}
     * @memberof HolderStateData
     */
    'requests': Array<PersonRequest>;
}
/**
 * 
 * @export
 * @interface HolderStateLink
 */
export interface HolderStateLink {
    /**
     * 
     * @type {string}
     * @memberof HolderStateLink
     */
    'href'?: string;
    /**
     * 
     * @type {string}
     * @memberof HolderStateLink
     */
    'type'?: string;
}
/**
 * 
 * @export
 * @interface HolderStateMeta
 */
export interface HolderStateMeta {
    /**
     * 
     * @type {IDMethods}
     * @memberof HolderStateMeta
     */
    'identProviders': IDMethods;
    /**
     * 
     * @type {HolderStateMetaOrderPresets}
     * @memberof HolderStateMeta
     */
    'orderPresets'?: HolderStateMetaOrderPresets;
    /**
     * 
     * @type {string}
     * @memberof HolderStateMeta
     */
    'verifierName'?: string;
    /**
     * 
     * @type {RequiredCredentials}
     * @memberof HolderStateMeta
     */
    'requiredCredentials': RequiredCredentials;
    /**
     * 
     * @type {HolderCredentialState}
     * @memberof HolderStateMeta
     */
    'credentialState'?: HolderCredentialState;
    /**
     * 
     * @type {string}
     * @memberof HolderStateMeta
     */
    'identificationState'?: HolderStateMetaIdentificationStateEnum;
    /**
     * 
     * @type {HolderStateLink}
     * @memberof HolderStateMeta
     */
    'link'?: HolderStateLink;
}

export const HolderStateMetaIdentificationStateEnum = {
    Mismatch: 'mismatch',
    Success: 'success',
    CustomerInputRequired: 'customerInputRequired',
    PendingIdentDataCorrection: 'pendingIdentDataCorrection',
    Error: 'error',
    Unknown: 'unknown',
    IdentError: 'identError'
} as const;

export type HolderStateMetaIdentificationStateEnum = typeof HolderStateMetaIdentificationStateEnum[keyof typeof HolderStateMetaIdentificationStateEnum];

/**
 * 
 * @export
 * @interface HolderStateMetaOrderPresets
 */
export interface HolderStateMetaOrderPresets {
    /**
     * 
     * @type {OrderPreset}
     * @memberof HolderStateMetaOrderPresets
     */
    'forOrg': OrderPreset;
    /**
     * 
     * @type {OrderPreset}
     * @memberof HolderStateMetaOrderPresets
     */
    'forPerson': OrderPreset;
    /**
     * 
     * @type {string}
     * @memberof HolderStateMetaOrderPresets
     */
    'selectedIdentProvider': HolderStateMetaOrderPresetsSelectedIdentProviderEnum;
}

export const HolderStateMetaOrderPresetsSelectedIdentProviderEnum = {
    CrefoFirmenwissen: 'crefo_firmenwissen',
    Admin: 'admin',
    ItmVideoident: 'itm_videoident',
    NectAutoident: 'nect_autoident',
    PinletterIdent: 'pinletter_ident',
    Did: 'did'
} as const;

export type HolderStateMetaOrderPresetsSelectedIdentProviderEnum = typeof HolderStateMetaOrderPresetsSelectedIdentProviderEnum[keyof typeof HolderStateMetaOrderPresetsSelectedIdentProviderEnum];

/**
 * 
 * @export
 * @interface IDMethods
 */
export interface IDMethods {
    /**
     * Defines all ident methods, that the verifier accepts for its\' holders identification. Methods which are accepted do not necessarily have to be offered.
     * @type {Array<string>}
     * @memberof IDMethods
     */
    'acceptedIDMethods': Array<IDMethodsAcceptedIDMethodsEnum>;
    /**
     * Defines all ident methods, that the verifier wants to offer its\' holders for identification. All methods which are offered must be accepted.
     * @type {Array<string>}
     * @memberof IDMethods
     */
    'offeredIDMethods': Array<IDMethodsOfferedIDMethodsEnum>;
}

export const IDMethodsAcceptedIDMethodsEnum = {
    ItmVideoident: 'itm_videoident',
    NectAutoident: 'nect_autoident',
    PinletterIdent: 'pinletter_ident'
} as const;

export type IDMethodsAcceptedIDMethodsEnum = typeof IDMethodsAcceptedIDMethodsEnum[keyof typeof IDMethodsAcceptedIDMethodsEnum];
export const IDMethodsOfferedIDMethodsEnum = {
    ItmVideoident: 'itm_videoident',
    NectAutoident: 'nect_autoident',
    PinletterIdent: 'pinletter_ident'
} as const;

export type IDMethodsOfferedIDMethodsEnum = typeof IDMethodsOfferedIDMethodsEnum[keyof typeof IDMethodsOfferedIDMethodsEnum];

/**
 * 
 * @export
 * @interface IdentRequestDto
 */
export interface IdentRequestDto {
    /**
     * 
     * @type {string}
     * @memberof IdentRequestDto
     */
    'selectedIdentProvider'?: IdentRequestDtoSelectedIdentProviderEnum;
    /**
     * 
     * @type {PersonDto}
     * @memberof IdentRequestDto
     */
    'selectedPerson'?: PersonDto;
    /**
     * 
     * @type {CompanyDto}
     * @memberof IdentRequestDto
     */
    'selectedCompany'?: CompanyDto;
}

export const IdentRequestDtoSelectedIdentProviderEnum = {
    ItmVideoident: 'itm_videoident',
    NectAutoident: 'nect_autoident',
    PinletterIdent: 'pinletter_ident'
} as const;

export type IdentRequestDtoSelectedIdentProviderEnum = typeof IdentRequestDtoSelectedIdentProviderEnum[keyof typeof IdentRequestDtoSelectedIdentProviderEnum];

/**
 * 
 * @export
 * @interface IsSameCrefoIdResponse
 */
export interface IsSameCrefoIdResponse {
    /**
     * 
     * @type {boolean}
     * @memberof IsSameCrefoIdResponse
     */
    'isSameCrefoId': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof IsSameCrefoIdResponse
     */
    'isMember': boolean;
    /**
     * 
     * @type {Company}
     * @memberof IsSameCrefoIdResponse
     */
    'verifiedFor'?: Company;
    /**
     * 
     * @type {Company}
     * @memberof IsSameCrefoIdResponse
     */
    'depositedOnAccount'?: Company;
}
/**
 * 
 * @export
 * @interface IsValidVerifierDto
 */
export interface IsValidVerifierDto {
    /**
     * 
     * @type {string}
     * @memberof IsValidVerifierDto
     */
    'verifierId': string;
    /**
     * 
     * @type {string}
     * @memberof IsValidVerifierDto
     */
    'apiKey': string;
}
/**
 * 
 * @export
 * @interface Manager
 */
export interface Manager {
    /**
     * 
     * @type {string}
     * @memberof Manager
     */
    'givenName': string;
    /**
     * 
     * @type {string}
     * @memberof Manager
     */
    'familyName': string;
    /**
     * 
     * @type {string}
     * @memberof Manager
     */
    'birthDate': string;
    /**
     * 
     * @type {string}
     * @memberof Manager
     */
    'crefoId': string;
    /**
     * 
     * @type {CrefoAddress}
     * @memberof Manager
     */
    'address': CrefoAddress;
    /**
     * 
     * @type {boolean}
     * @memberof Manager
     */
    'isAuthorizedRepresentative': boolean;
}
/**
 * 
 * @export
 * @interface MetaData
 */
export interface MetaData {
    /**
     * Timestamp (unix epoch) when this information was created.
     * @type {number}
     * @memberof MetaData
     */
    'issuedAt': number;
    /**
     * The user (DID) to that this information regards to.
     * @type {string}
     * @memberof MetaData
     */
    'id': string;
    /**
     * This endpoint (url) provided by CrefoTrust can be used to check the current staus of the provided information.
     * @type {string}
     * @memberof MetaData
     */
    'status': string;
    /**
     * Issuer (DID) of the credential data.
     * @type {string}
     * @memberof MetaData
     */
    'issuer': string;
}
/**
 * 
 * @export
 * @interface Name
 */
export interface Name {
    /**
     * 
     * @type {string}
     * @memberof Name
     */
    'familyName': string;
    /**
     * 
     * @type {string}
     * @memberof Name
     */
    'givenName': string;
}
/**
 * 
 * @export
 * @interface OrderAuthRedirects
 */
export interface OrderAuthRedirects {
    /**
     * ClientId to be used for the auth provider.
     * @type {string}
     * @memberof OrderAuthRedirects
     */
    'clientId': string;
    /**
     * Base Url to the authority
     * @type {string}
     * @memberof OrderAuthRedirects
     */
    'authority': string;
    /**
     * Redirect URI to the user frontend.
     * @type {string}
     * @memberof OrderAuthRedirects
     */
    'redirectUri': string;
    /**
     * Redirect URI to the login page of the auth provider.
     * @type {string}
     * @memberof OrderAuthRedirects
     */
    'loginUri': string;
    /**
     * Redirect URI to the registration page of the auth provider.
     * @type {string}
     * @memberof OrderAuthRedirects
     */
    'registrationUri': string;
    /**
     * 
     * @type {string}
     * @memberof OrderAuthRedirects
     */
    'prk': string;
    /**
     * 
     * @type {string}
     * @memberof OrderAuthRedirects
     */
    'title': string;
}
/**
 * 
 * @export
 * @interface OrderCreationResponse
 */
export interface OrderCreationResponse {
    /**
     * Entry point (url) for the holder to start into the CrefoTrust process.
     * @type {string}
     * @memberof OrderCreationResponse
     */
    'url': string;
    /**
     * Unique identifier to reference the order.
     * @type {string}
     * @memberof OrderCreationResponse
     */
    'orderId': string;
    /**
     * An arbitrary data structure provided by the verifier while creating the order.
     * @type {object}
     * @memberof OrderCreationResponse
     */
    'verifierRef'?: object;
    /**
     * 
     * @type {OrderAuthRedirects}
     * @memberof OrderCreationResponse
     */
    'redirects': OrderAuthRedirects;
    /**
     * 
     * @type {string}
     * @memberof OrderCreationResponse
     */
    'crefoId': string;
    /**
     * 
     * @type {string}
     * @memberof OrderCreationResponse
     */
    'verifierName': string;
}
/**
 * 
 * @export
 * @interface OrderOptions
 */
export interface OrderOptions {
    /**
     * Name of the usecase that is going to be related to this
     * @type {string}
     * @memberof OrderOptions
     */
    'useCase'?: string;
    /**
     * Brand name of the verifier.
     * @type {string}
     * @memberof OrderOptions
     */
    'verifierName'?: string;
    /**
     * An arbitrary data structure provided by the verifier. Content will be transmitted on every response to the verifier.
     * @type {object}
     * @memberof OrderOptions
     */
    'verifierRef'?: object;
}
/**
 * 
 * @export
 * @interface OrderPreset
 */
export interface OrderPreset {
    /**
     * An order preset set to mandatory forces the holder to verify for the provided crefoId.
     * @type {boolean}
     * @memberof OrderPreset
     */
    'mandatory': boolean;
    /**
     * Defines the person / company the holder should be verify for / as.
     * @type {string}
     * @memberof OrderPreset
     */
    'crefoId': string;
    /**
     * 
     * @type {string}
     * @memberof OrderPreset
     */
    'did'?: string;
}
/**
 * 
 * @export
 * @interface OrderRedirect
 */
export interface OrderRedirect {
    /**
     * Defines a url from which the holder originates from..
     * @type {string}
     * @memberof OrderRedirect
     */
    'origin'?: string;
    /**
     * Defines a url where the holder is redirected to when the verification and the order succeeds.
     * @type {string}
     * @memberof OrderRedirect
     */
    'success': string;
    /**
     * Defines a url where the holder is redirected to when the verification and the order fails.
     * @type {string}
     * @memberof OrderRedirect
     */
    'failure': string;
}
/**
 * 
 * @export
 * @interface OrderStep
 */
export interface OrderStep {
    /**
     * Step code, see external docs.
     * @type {number}
     * @memberof OrderStep
     */
    'code': number;
    /**
     * A short description.
     * @type {string}
     * @memberof OrderStep
     */
    'comment': string;
    /**
     * Timestamp when this step occured.
     * @type {string}
     * @memberof OrderStep
     */
    'timestamp': string;
    /**
     * Defines whether or not this is only an internal event.
     * @type {boolean}
     * @memberof OrderStep
     */
    'internal': boolean;
    /**
     * The content of the step, see external docs.
     * @type {object}
     * @memberof OrderStep
     */
    'payload': object;
}
/**
 * 
 * @export
 * @interface OrderStepPayload
 */
export interface OrderStepPayload {
    /**
     * 
     * @type {string}
     * @memberof OrderStepPayload
     */
    'value': string;
}
/**
 * 
 * @export
 * @interface OrgCredentialsMeta
 */
export interface OrgCredentialsMeta {
    /**
     * 
     * @type {OrgCredentialsMetaLegalName}
     * @memberof OrgCredentialsMeta
     */
    'legalName'?: OrgCredentialsMetaLegalName;
    /**
     * 
     * @type {OrgCredentialsMetaLegalForm}
     * @memberof OrgCredentialsMeta
     */
    'legalForm'?: OrgCredentialsMetaLegalForm;
    /**
     * 
     * @type {PersonCredentialsMetaAddress}
     * @memberof OrgCredentialsMeta
     */
    'address'?: PersonCredentialsMetaAddress;
    /**
     * 
     * @type {PersonCredentialsMetaCrefoId}
     * @memberof OrgCredentialsMeta
     */
    'crefoId'?: PersonCredentialsMetaCrefoId;
}
/**
 * Meta information about the legal form related data.
 * @export
 * @interface OrgCredentialsMetaLegalForm
 */
export interface OrgCredentialsMetaLegalForm {
    /**
     * Timestamp (unix epoch) when this information was created.
     * @type {number}
     * @memberof OrgCredentialsMetaLegalForm
     */
    'issuedAt': number;
    /**
     * The user (DID) to that this information regards to.
     * @type {string}
     * @memberof OrgCredentialsMetaLegalForm
     */
    'id': string;
    /**
     * This endpoint (url) provided by CrefoTrust can be used to check the current staus of the provided information.
     * @type {string}
     * @memberof OrgCredentialsMetaLegalForm
     */
    'status': string;
    /**
     * Issuer (DID) of the credential data.
     * @type {string}
     * @memberof OrgCredentialsMetaLegalForm
     */
    'issuer': string;
}
/**
 * Meta information about the legal name related data.
 * @export
 * @interface OrgCredentialsMetaLegalName
 */
export interface OrgCredentialsMetaLegalName {
    /**
     * Timestamp (unix epoch) when this information was created.
     * @type {number}
     * @memberof OrgCredentialsMetaLegalName
     */
    'issuedAt': number;
    /**
     * The user (DID) to that this information regards to.
     * @type {string}
     * @memberof OrgCredentialsMetaLegalName
     */
    'id': string;
    /**
     * This endpoint (url) provided by CrefoTrust can be used to check the current staus of the provided information.
     * @type {string}
     * @memberof OrgCredentialsMetaLegalName
     */
    'status': string;
    /**
     * Issuer (DID) of the credential data.
     * @type {string}
     * @memberof OrgCredentialsMetaLegalName
     */
    'issuer': string;
}
/**
 * 
 * @export
 * @interface OrgMeta
 */
export interface OrgMeta {
    /**
     * The did of the company.
     * @type {string}
     * @memberof OrgMeta
     */
    'id'?: string;
    /**
     * 
     * @type {OrgMetaCredentials}
     * @memberof OrgMeta
     */
    'credentials'?: OrgMetaCredentials;
}
/**
 * Meta information about credentials.
 * @export
 * @interface OrgMetaCredentials
 */
export interface OrgMetaCredentials {
    /**
     * 
     * @type {OrgCredentialsMetaLegalName}
     * @memberof OrgMetaCredentials
     */
    'legalName'?: OrgCredentialsMetaLegalName;
    /**
     * 
     * @type {OrgCredentialsMetaLegalForm}
     * @memberof OrgMetaCredentials
     */
    'legalForm'?: OrgCredentialsMetaLegalForm;
    /**
     * 
     * @type {PersonCredentialsMetaAddress}
     * @memberof OrgMetaCredentials
     */
    'address'?: PersonCredentialsMetaAddress;
    /**
     * 
     * @type {PersonCredentialsMetaCrefoId}
     * @memberof OrgMetaCredentials
     */
    'crefoId'?: PersonCredentialsMetaCrefoId;
}
/**
 * 
 * @export
 * @interface OrganisationDto
 */
export interface OrganisationDto {
    /**
     * 
     * @type {string}
     * @memberof OrganisationDto
     */
    'legalName': string;
    /**
     * 
     * @type {string}
     * @memberof OrganisationDto
     */
    'legalForm'?: string;
    /**
     * 
     * @type {string}
     * @memberof OrganisationDto
     */
    'department'?: string;
}
/**
 * 
 * @export
 * @interface PartialTypeClass
 */
export interface PartialTypeClass {
    /**
     * 
     * @type {string}
     * @memberof PartialTypeClass
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof PartialTypeClass
     */
    'type'?: PartialTypeClassTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof PartialTypeClass
     */
    'did'?: string;
    /**
     * 
     * @type {string}
     * @memberof PartialTypeClass
     */
    'dataSource'?: PartialTypeClassDataSourceEnum;
    /**
     * 
     * @type {object}
     * @memberof PartialTypeClass
     */
    'payload'?: object;
    /**
     * 
     * @type {string}
     * @memberof PartialTypeClass
     */
    'jwt'?: string;
    /**
     * 
     * @type {string}
     * @memberof PartialTypeClass
     */
    'status'?: PartialTypeClassStatusEnum;
    /**
     * 
     * @type {number}
     * @memberof PartialTypeClass
     */
    'createdAt'?: number;
    /**
     * 
     * @type {number}
     * @memberof PartialTypeClass
     */
    'updatedAt'?: number;
    /**
     * 
     * @type {object}
     * @memberof PartialTypeClass
     */
    'dataEnabler'?: object;
    /**
     * 
     * @type {string}
     * @memberof PartialTypeClass
     */
    'reason'?: string;
}

export const PartialTypeClassTypeEnum = {
    Crid: 'crid',
    Name: 'name',
    Address: 'address',
    Nationality: 'nationality',
    Birthdate: 'birthdate',
    CrefoId: 'crefoId',
    LegalName: 'legalName',
    LegalForm: 'legalForm',
    AuthorizedRepresentative: 'authorizedRepresentative',
    CompanyAgent: 'companyAgent'
} as const;

export type PartialTypeClassTypeEnum = typeof PartialTypeClassTypeEnum[keyof typeof PartialTypeClassTypeEnum];
export const PartialTypeClassDataSourceEnum = {
    CrefoFirmenwissen: 'crefo_firmenwissen',
    Admin: 'admin',
    ItmVideoident: 'itm_videoident',
    NectAutoident: 'nect_autoident',
    PinletterIdent: 'pinletter_ident',
    Did: 'did'
} as const;

export type PartialTypeClassDataSourceEnum = typeof PartialTypeClassDataSourceEnum[keyof typeof PartialTypeClassDataSourceEnum];
export const PartialTypeClassStatusEnum = {
    Valid: 'VALID',
    Revoked: 'REVOKED',
    Expired: 'EXPIRED',
    NotFound: 'NOT_FOUND'
} as const;

export type PartialTypeClassStatusEnum = typeof PartialTypeClassStatusEnum[keyof typeof PartialTypeClassStatusEnum];

/**
 * 
 * @export
 * @interface Person
 */
export interface Person {
    /**
     * 
     * @type {string}
     * @memberof Person
     */
    'givenName'?: string;
    /**
     * 
     * @type {string}
     * @memberof Person
     */
    'familyName'?: string;
    /**
     * 
     * @type {string}
     * @memberof Person
     */
    'birthDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof Person
     */
    'postalCode'?: string;
    /**
     * 
     * @type {string}
     * @memberof Person
     */
    'email'?: string;
    /**
     * 
     * @type {CrefoAddress}
     * @memberof Person
     */
    'address'?: CrefoAddress;
    /**
     * 
     * @type {string}
     * @memberof Person
     */
    'crefoId'?: string;
}
/**
 * 
 * @export
 * @interface PersonCredentialsMeta
 */
export interface PersonCredentialsMeta {
    /**
     * 
     * @type {PersonCredentialsMetaCrid}
     * @memberof PersonCredentialsMeta
     */
    'crid'?: PersonCredentialsMetaCrid;
    /**
     * 
     * @type {PersonCredentialsMetaCrefoId}
     * @memberof PersonCredentialsMeta
     */
    'crefoId'?: PersonCredentialsMetaCrefoId;
    /**
     * 
     * @type {PersonCredentialsMetaAddress}
     * @memberof PersonCredentialsMeta
     */
    'address'?: PersonCredentialsMetaAddress;
    /**
     * 
     * @type {PersonCredentialsMetaName}
     * @memberof PersonCredentialsMeta
     */
    'name'?: PersonCredentialsMetaName;
    /**
     * 
     * @type {PersonCredentialsMetaBirthdate}
     * @memberof PersonCredentialsMeta
     */
    'birthdate'?: PersonCredentialsMetaBirthdate;
    /**
     * 
     * @type {PersonCredentialsMetaNationality}
     * @memberof PersonCredentialsMeta
     */
    'nationality'?: PersonCredentialsMetaNationality;
}
/**
 * Meta information about the address related data.
 * @export
 * @interface PersonCredentialsMetaAddress
 */
export interface PersonCredentialsMetaAddress {
    /**
     * Timestamp (unix epoch) when this information was created.
     * @type {number}
     * @memberof PersonCredentialsMetaAddress
     */
    'issuedAt': number;
    /**
     * The user (DID) to that this information regards to.
     * @type {string}
     * @memberof PersonCredentialsMetaAddress
     */
    'id': string;
    /**
     * This endpoint (url) provided by CrefoTrust can be used to check the current staus of the provided information.
     * @type {string}
     * @memberof PersonCredentialsMetaAddress
     */
    'status': string;
    /**
     * Issuer (DID) of the credential data.
     * @type {string}
     * @memberof PersonCredentialsMetaAddress
     */
    'issuer': string;
}
/**
 * Meta information about the birthdate related data.
 * @export
 * @interface PersonCredentialsMetaBirthdate
 */
export interface PersonCredentialsMetaBirthdate {
    /**
     * Timestamp (unix epoch) when this information was created.
     * @type {number}
     * @memberof PersonCredentialsMetaBirthdate
     */
    'issuedAt': number;
    /**
     * The user (DID) to that this information regards to.
     * @type {string}
     * @memberof PersonCredentialsMetaBirthdate
     */
    'id': string;
    /**
     * This endpoint (url) provided by CrefoTrust can be used to check the current staus of the provided information.
     * @type {string}
     * @memberof PersonCredentialsMetaBirthdate
     */
    'status': string;
    /**
     * Issuer (DID) of the credential data.
     * @type {string}
     * @memberof PersonCredentialsMetaBirthdate
     */
    'issuer': string;
}
/**
 * Meta information about the crefoId related data.
 * @export
 * @interface PersonCredentialsMetaCrefoId
 */
export interface PersonCredentialsMetaCrefoId {
    /**
     * Timestamp (unix epoch) when this information was created.
     * @type {number}
     * @memberof PersonCredentialsMetaCrefoId
     */
    'issuedAt': number;
    /**
     * The user (DID) to that this information regards to.
     * @type {string}
     * @memberof PersonCredentialsMetaCrefoId
     */
    'id': string;
    /**
     * This endpoint (url) provided by CrefoTrust can be used to check the current staus of the provided information.
     * @type {string}
     * @memberof PersonCredentialsMetaCrefoId
     */
    'status': string;
    /**
     * Issuer (DID) of the credential data.
     * @type {string}
     * @memberof PersonCredentialsMetaCrefoId
     */
    'issuer': string;
}
/**
 * Meta information about the crid related data.
 * @export
 * @interface PersonCredentialsMetaCrid
 */
export interface PersonCredentialsMetaCrid {
    /**
     * Timestamp (unix epoch) when this information was created.
     * @type {number}
     * @memberof PersonCredentialsMetaCrid
     */
    'issuedAt': number;
    /**
     * The user (DID) to that this information regards to.
     * @type {string}
     * @memberof PersonCredentialsMetaCrid
     */
    'id': string;
    /**
     * This endpoint (url) provided by CrefoTrust can be used to check the current staus of the provided information.
     * @type {string}
     * @memberof PersonCredentialsMetaCrid
     */
    'status': string;
    /**
     * Issuer (DID) of the credential data.
     * @type {string}
     * @memberof PersonCredentialsMetaCrid
     */
    'issuer': string;
}
/**
 * Meta information about the name related data.
 * @export
 * @interface PersonCredentialsMetaName
 */
export interface PersonCredentialsMetaName {
    /**
     * Timestamp (unix epoch) when this information was created.
     * @type {number}
     * @memberof PersonCredentialsMetaName
     */
    'issuedAt': number;
    /**
     * The user (DID) to that this information regards to.
     * @type {string}
     * @memberof PersonCredentialsMetaName
     */
    'id': string;
    /**
     * This endpoint (url) provided by CrefoTrust can be used to check the current staus of the provided information.
     * @type {string}
     * @memberof PersonCredentialsMetaName
     */
    'status': string;
    /**
     * Issuer (DID) of the credential data.
     * @type {string}
     * @memberof PersonCredentialsMetaName
     */
    'issuer': string;
}
/**
 * Meta information about the nationality related data.
 * @export
 * @interface PersonCredentialsMetaNationality
 */
export interface PersonCredentialsMetaNationality {
    /**
     * Timestamp (unix epoch) when this information was created.
     * @type {number}
     * @memberof PersonCredentialsMetaNationality
     */
    'issuedAt': number;
    /**
     * The user (DID) to that this information regards to.
     * @type {string}
     * @memberof PersonCredentialsMetaNationality
     */
    'id': string;
    /**
     * This endpoint (url) provided by CrefoTrust can be used to check the current staus of the provided information.
     * @type {string}
     * @memberof PersonCredentialsMetaNationality
     */
    'status': string;
    /**
     * Issuer (DID) of the credential data.
     * @type {string}
     * @memberof PersonCredentialsMetaNationality
     */
    'issuer': string;
}
/**
 * 
 * @export
 * @interface PersonDto
 */
export interface PersonDto {
    /**
     * 
     * @type {string}
     * @memberof PersonDto
     */
    'givenName': string;
    /**
     * 
     * @type {string}
     * @memberof PersonDto
     */
    'familyName': string;
    /**
     * 
     * @type {string}
     * @memberof PersonDto
     */
    'birthDate': string;
    /**
     * 
     * @type {string}
     * @memberof PersonDto
     */
    'crefoId': string;
    /**
     * 
     * @type {AddressDto}
     * @memberof PersonDto
     */
    'address': AddressDto;
    /**
     * 
     * @type {string}
     * @memberof PersonDto
     */
    'email': string;
}
/**
 * 
 * @export
 * @interface PersonMatch
 */
export interface PersonMatch {
    /**
     * 
     * @type {Person}
     * @memberof PersonMatch
     */
    'ssu': Person;
    /**
     * 
     * @type {Person}
     * @memberof PersonMatch
     */
    'ident': Person;
}
/**
 * 
 * @export
 * @interface PersonMatchError
 */
export interface PersonMatchError {
    /**
     * 
     * @type {string}
     * @memberof PersonMatchError
     */
    'did': string;
    /**
     * 
     * @type {string}
     * @memberof PersonMatchError
     */
    'orderId': string;
    /**
     * 
     * @type {string}
     * @memberof PersonMatchError
     */
    'crefoId': string;
    /**
     * 
     * @type {Array<ComparisonResultObject>}
     * @memberof PersonMatchError
     */
    'stacktrace': Array<ComparisonResultObject>;
    /**
     * 
     * @type {PersonMatch}
     * @memberof PersonMatchError
     */
    'personMatch': PersonMatch;
}
/**
 * 
 * @export
 * @interface PersonMeta
 */
export interface PersonMeta {
    /**
     * The did of the person.
     * @type {string}
     * @memberof PersonMeta
     */
    'id'?: string;
    /**
     * 
     * @type {PersonMetaCredentials}
     * @memberof PersonMeta
     */
    'credentials'?: PersonMetaCredentials;
}
/**
 * Meta information about credentials.
 * @export
 * @interface PersonMetaCredentials
 */
export interface PersonMetaCredentials {
    /**
     * 
     * @type {PersonCredentialsMetaCrid}
     * @memberof PersonMetaCredentials
     */
    'crid'?: PersonCredentialsMetaCrid;
    /**
     * 
     * @type {PersonCredentialsMetaCrefoId}
     * @memberof PersonMetaCredentials
     */
    'crefoId'?: PersonCredentialsMetaCrefoId;
    /**
     * 
     * @type {PersonCredentialsMetaAddress}
     * @memberof PersonMetaCredentials
     */
    'address'?: PersonCredentialsMetaAddress;
    /**
     * 
     * @type {PersonCredentialsMetaName}
     * @memberof PersonMetaCredentials
     */
    'name'?: PersonCredentialsMetaName;
    /**
     * 
     * @type {PersonCredentialsMetaBirthdate}
     * @memberof PersonMetaCredentials
     */
    'birthdate'?: PersonCredentialsMetaBirthdate;
    /**
     * 
     * @type {PersonCredentialsMetaNationality}
     * @memberof PersonMetaCredentials
     */
    'nationality'?: PersonCredentialsMetaNationality;
}
/**
 * 
 * @export
 * @interface PersonRequest
 */
export interface PersonRequest {
    /**
     * 
     * @type {string}
     * @memberof PersonRequest
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof PersonRequest
     */
    'type': PersonRequestTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof PersonRequest
     */
    'did': string;
    /**
     * 
     * @type {string}
     * @memberof PersonRequest
     */
    'orderId'?: string;
    /**
     * 
     * @type {string}
     * @memberof PersonRequest
     */
    'dataSource': PersonRequestDataSourceEnum;
    /**
     * 
     * @type {string}
     * @memberof PersonRequest
     */
    'state': PersonRequestStateEnum;
    /**
     * 
     * @type {Array<RequestActionClass>}
     * @memberof PersonRequest
     */
    'actions': Array<RequestActionClass>;
    /**
     * 
     * @type {string}
     * @memberof PersonRequest
     */
    'stateReason'?: string;
    /**
     * 
     * @type {string}
     * @memberof PersonRequest
     */
    'comment'?: string;
    /**
     * 
     * @type {object}
     * @memberof PersonRequest
     */
    'createdAt': object;
    /**
     * 
     * @type {object}
     * @memberof PersonRequest
     */
    'updatedAt': object;
    /**
     * 
     * @type {string}
     * @memberof PersonRequest
     */
    'idMethod': PersonRequestIdMethodEnum;
    /**
     * 
     * @type {string}
     * @memberof PersonRequest
     */
    'requestor': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof PersonRequest
     */
    'customerRequiredData': Array<PersonRequestCustomerRequiredDataEnum>;
    /**
     * 
     * @type {PersonRequestPayload}
     * @memberof PersonRequest
     */
    'payload': PersonRequestPayload;
    /**
     * 
     * @type {object}
     * @memberof PersonRequest
     */
    'customerProvidedData'?: object;
}

export const PersonRequestTypeEnum = {
    Org: 'org',
    Person: 'person'
} as const;

export type PersonRequestTypeEnum = typeof PersonRequestTypeEnum[keyof typeof PersonRequestTypeEnum];
export const PersonRequestDataSourceEnum = {
    ItmVideoident: 'itm_videoident',
    NectAutoident: 'nect_autoident'
} as const;

export type PersonRequestDataSourceEnum = typeof PersonRequestDataSourceEnum[keyof typeof PersonRequestDataSourceEnum];
export const PersonRequestStateEnum = {
    Open: 'OPEN',
    Accepted: 'ACCEPTED',
    Rejected: 'REJECTED',
    Inprogress: 'INPROGRESS',
    CustomerPostprocessing: 'CUSTOMER_POSTPROCESSING',
    Closed: 'CLOSED'
} as const;

export type PersonRequestStateEnum = typeof PersonRequestStateEnum[keyof typeof PersonRequestStateEnum];
export const PersonRequestIdMethodEnum = {
    ItmVideoident: 'itm_videoident',
    NectAutoident: 'nect_autoident'
} as const;

export type PersonRequestIdMethodEnum = typeof PersonRequestIdMethodEnum[keyof typeof PersonRequestIdMethodEnum];
export const PersonRequestCustomerRequiredDataEnum = {
    Crid: 'crid',
    Name: 'name',
    Address: 'address',
    Nationality: 'nationality',
    Birthdate: 'birthdate',
    CrefoId: 'crefoId',
    LegalName: 'legalName',
    LegalForm: 'legalForm',
    AuthorizedRepresentative: 'authorizedRepresentative',
    CompanyAgent: 'companyAgent'
} as const;

export type PersonRequestCustomerRequiredDataEnum = typeof PersonRequestCustomerRequiredDataEnum[keyof typeof PersonRequestCustomerRequiredDataEnum];

/**
 * 
 * @export
 * @interface PersonRequestPayload
 */
export interface PersonRequestPayload {
    /**
     * 
     * @type {string}
     * @memberof PersonRequestPayload
     */
    'givenName': string;
    /**
     * 
     * @type {string}
     * @memberof PersonRequestPayload
     */
    'familyName': string;
    /**
     * 
     * @type {string}
     * @memberof PersonRequestPayload
     */
    'birthDate': string;
    /**
     * 
     * @type {string}
     * @memberof PersonRequestPayload
     */
    'crefoId': string;
    /**
     * 
     * @type {string}
     * @memberof PersonRequestPayload
     */
    'email': string;
    /**
     * 
     * @type {RequestAddress}
     * @memberof PersonRequestPayload
     */
    'address': RequestAddress;
}
/**
 * 
 * @export
 * @interface RejectOrderDto
 */
export interface RejectOrderDto {
    /**
     * 
     * @type {string}
     * @memberof RejectOrderDto
     */
    'comment'?: string;
}
/**
 * 
 * @export
 * @interface RequestActionClass
 */
export interface RequestActionClass {
    /**
     * 
     * @type {string}
     * @memberof RequestActionClass
     */
    'actionType': string;
    /**
     * 
     * @type {CrefoIdentOrderLink}
     * @memberof RequestActionClass
     */
    'value': CrefoIdentOrderLink;
}
/**
 * 
 * @export
 * @interface RequestAddress
 */
export interface RequestAddress {
    /**
     * 
     * @type {string}
     * @memberof RequestAddress
     */
    'streetAddress': string;
    /**
     * 
     * @type {string}
     * @memberof RequestAddress
     */
    'postalCode': string;
    /**
     * 
     * @type {string}
     * @memberof RequestAddress
     */
    'addressLocality': string;
    /**
     * 
     * @type {string}
     * @memberof RequestAddress
     */
    'addressCountry': string;
}
/**
 * 
 * @export
 * @interface RequestsControllerUpdateRequest200Response
 */
export interface RequestsControllerUpdateRequest200Response {
    /**
     * 
     * @type {string}
     * @memberof RequestsControllerUpdateRequest200Response
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof RequestsControllerUpdateRequest200Response
     */
    'type': RequestsControllerUpdateRequest200ResponseTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof RequestsControllerUpdateRequest200Response
     */
    'did': string;
    /**
     * 
     * @type {string}
     * @memberof RequestsControllerUpdateRequest200Response
     */
    'orderId'?: string;
    /**
     * 
     * @type {string}
     * @memberof RequestsControllerUpdateRequest200Response
     */
    'dataSource': RequestsControllerUpdateRequest200ResponseDataSourceEnum;
    /**
     * 
     * @type {string}
     * @memberof RequestsControllerUpdateRequest200Response
     */
    'state': RequestsControllerUpdateRequest200ResponseStateEnum;
    /**
     * 
     * @type {Array<RequestActionClass>}
     * @memberof RequestsControllerUpdateRequest200Response
     */
    'actions': Array<RequestActionClass>;
    /**
     * 
     * @type {string}
     * @memberof RequestsControllerUpdateRequest200Response
     */
    'stateReason'?: string;
    /**
     * 
     * @type {string}
     * @memberof RequestsControllerUpdateRequest200Response
     */
    'comment'?: string;
    /**
     * 
     * @type {object}
     * @memberof RequestsControllerUpdateRequest200Response
     */
    'createdAt': object;
    /**
     * 
     * @type {object}
     * @memberof RequestsControllerUpdateRequest200Response
     */
    'updatedAt': object;
    /**
     * 
     * @type {string}
     * @memberof RequestsControllerUpdateRequest200Response
     */
    'idMethod': RequestsControllerUpdateRequest200ResponseIdMethodEnum;
    /**
     * 
     * @type {string}
     * @memberof RequestsControllerUpdateRequest200Response
     */
    'requestor': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof RequestsControllerUpdateRequest200Response
     */
    'customerRequiredData': Array<RequestsControllerUpdateRequest200ResponseCustomerRequiredDataEnum>;
    /**
     * 
     * @type {PersonRequestPayload}
     * @memberof RequestsControllerUpdateRequest200Response
     */
    'payload': PersonRequestPayload;
    /**
     * 
     * @type {object}
     * @memberof RequestsControllerUpdateRequest200Response
     */
    'customerProvidedData'?: object;
}

export const RequestsControllerUpdateRequest200ResponseTypeEnum = {
    Org: 'org',
    Person: 'person'
} as const;

export type RequestsControllerUpdateRequest200ResponseTypeEnum = typeof RequestsControllerUpdateRequest200ResponseTypeEnum[keyof typeof RequestsControllerUpdateRequest200ResponseTypeEnum];
export const RequestsControllerUpdateRequest200ResponseDataSourceEnum = {
    ItmVideoident: 'itm_videoident',
    NectAutoident: 'nect_autoident'
} as const;

export type RequestsControllerUpdateRequest200ResponseDataSourceEnum = typeof RequestsControllerUpdateRequest200ResponseDataSourceEnum[keyof typeof RequestsControllerUpdateRequest200ResponseDataSourceEnum];
export const RequestsControllerUpdateRequest200ResponseStateEnum = {
    Open: 'OPEN',
    Accepted: 'ACCEPTED',
    Rejected: 'REJECTED',
    Inprogress: 'INPROGRESS',
    CustomerPostprocessing: 'CUSTOMER_POSTPROCESSING',
    Closed: 'CLOSED'
} as const;

export type RequestsControllerUpdateRequest200ResponseStateEnum = typeof RequestsControllerUpdateRequest200ResponseStateEnum[keyof typeof RequestsControllerUpdateRequest200ResponseStateEnum];
export const RequestsControllerUpdateRequest200ResponseIdMethodEnum = {
    ItmVideoident: 'itm_videoident',
    NectAutoident: 'nect_autoident'
} as const;

export type RequestsControllerUpdateRequest200ResponseIdMethodEnum = typeof RequestsControllerUpdateRequest200ResponseIdMethodEnum[keyof typeof RequestsControllerUpdateRequest200ResponseIdMethodEnum];
export const RequestsControllerUpdateRequest200ResponseCustomerRequiredDataEnum = {
    Crid: 'crid',
    Name: 'name',
    Address: 'address',
    Nationality: 'nationality',
    Birthdate: 'birthdate',
    CrefoId: 'crefoId',
    LegalName: 'legalName',
    LegalForm: 'legalForm',
    AuthorizedRepresentative: 'authorizedRepresentative',
    CompanyAgent: 'companyAgent'
} as const;

export type RequestsControllerUpdateRequest200ResponseCustomerRequiredDataEnum = typeof RequestsControllerUpdateRequest200ResponseCustomerRequiredDataEnum[keyof typeof RequestsControllerUpdateRequest200ResponseCustomerRequiredDataEnum];

/**
 * 
 * @export
 * @interface RequiredCredentials
 */
export interface RequiredCredentials {
    /**
     * Required person credentials to complete the order.
     * @type {Array<string>}
     * @memberof RequiredCredentials
     */
    'person': Array<RequiredCredentialsPersonEnum>;
    /**
     * Required company credentials to complete the order.
     * @type {Array<string>}
     * @memberof RequiredCredentials
     */
    'org': Array<RequiredCredentialsOrgEnum>;
    /**
     * Authorized representative credential to complete the order.
     * @type {boolean}
     * @memberof RequiredCredentials
     */
    'authorizedRepresentative'?: boolean;
    /**
     * Company agent credential to complete the order.
     * @type {boolean}
     * @memberof RequiredCredentials
     */
    'companyAgent'?: boolean;
}

export const RequiredCredentialsPersonEnum = {
    Crid: 'crid',
    CrefoId: 'crefoId',
    Name: 'name',
    Address: 'address',
    Nationality: 'nationality',
    Birthdate: 'birthdate'
} as const;

export type RequiredCredentialsPersonEnum = typeof RequiredCredentialsPersonEnum[keyof typeof RequiredCredentialsPersonEnum];
export const RequiredCredentialsOrgEnum = {
    LegalForm: 'legalForm',
    LegalName: 'legalName',
    CrefoId: 'crefoId',
    Address: 'address'
} as const;

export type RequiredCredentialsOrgEnum = typeof RequiredCredentialsOrgEnum[keyof typeof RequiredCredentialsOrgEnum];

/**
 * 
 * @export
 * @interface RevokeCredentialsDto
 */
export interface RevokeCredentialsDto {
    /**
     * 
     * @type {Array<PartialTypeClass>}
     * @memberof RevokeCredentialsDto
     */
    'credentialFilters': Array<PartialTypeClass>;
    /**
     * 
     * @type {string}
     * @memberof RevokeCredentialsDto
     */
    'reason': string;
}
/**
 * 
 * @export
 * @interface RoutingState
 */
export interface RoutingState {
    /**
     * 
     * @type {Array<string>}
     * @memberof RoutingState
     */
    'routes': Array<RoutingStateRoutesEnum>;
}

export const RoutingStateRoutesEnum = {
    CompanySearch: 'COMPANY_SEARCH',
    SelectIdentprovider: 'SELECT_IDENTPROVIDER',
    Verification: 'VERIFICATION',
    ApproveCredentials: 'APPROVE_CREDENTIALS',
    CustomerInputRequired: 'CUSTOMER_INPUT_REQUIRED',
    CredentialsInsufficient: 'CREDENTIALS_INSUFFICIENT',
    MyEsgVerification: 'MY_ESG_VERIFICATION'
} as const;

export type RoutingStateRoutesEnum = typeof RoutingStateRoutesEnum[keyof typeof RoutingStateRoutesEnum];

/**
 * 
 * @export
 * @interface SuccessResponse
 */
export interface SuccessResponse {
    /**
     * 
     * @type {boolean}
     * @memberof SuccessResponse
     */
    'success': boolean;
}
/**
 * 
 * @export
 * @interface UpdateOrderDto
 */
export interface UpdateOrderDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateOrderDto
     */
    'verifierId'?: string;
    /**
     * 
     * @type {RequiredCredentials}
     * @memberof UpdateOrderDto
     */
    'requiredCredentials'?: RequiredCredentials;
    /**
     * Webhook triggered after completion. See VerifierWebhookResponse below for the response payload structure. Order-id will be appended.
     * @type {string}
     * @memberof UpdateOrderDto
     */
    'webhook'?: string;
    /**
     * 
     * @type {OrderRedirect}
     * @memberof UpdateOrderDto
     */
    'redirect'?: OrderRedirect;
    /**
     * 
     * @type {OrderOptions}
     * @memberof UpdateOrderDto
     */
    'options'?: OrderOptions;
    /**
     * Defines all ident methods, that the verifier accepts for its\' holders identification. Methods which are accepted do not necessarily have to be offered.
     * @type {Array<string>}
     * @memberof UpdateOrderDto
     */
    'acceptedIdMethods'?: Array<UpdateOrderDtoAcceptedIdMethodsEnum>;
    /**
     * Defines all ident methods, that the verifier wants to offer its\' holders for identification. All methods which are offered must be accepted.
     * @type {Array<string>}
     * @memberof UpdateOrderDto
     */
    'offeredIdMethods'?: Array<UpdateOrderDtoOfferedIdMethodsEnum>;
    /**
     * 
     * @type {OrderPreset}
     * @memberof UpdateOrderDto
     */
    'forOrg'?: OrderPreset;
    /**
     * 
     * @type {CreateOrderDtoForPerson}
     * @memberof UpdateOrderDto
     */
    'forPerson'?: CreateOrderDtoForPerson;
    /**
     * 
     * @type {CreateOrderDtoAuth}
     * @memberof UpdateOrderDto
     */
    'auth'?: CreateOrderDtoAuth;
    /**
     * 
     * @type {string}
     * @memberof UpdateOrderDto
     */
    'selectedIdentProvider'?: UpdateOrderDtoSelectedIdentProviderEnum;
    /**
     * 
     * @type {Person}
     * @memberof UpdateOrderDto
     */
    'person'?: Person;
    /**
     * 
     * @type {Company}
     * @memberof UpdateOrderDto
     */
    'company'?: Company;
}

export const UpdateOrderDtoAcceptedIdMethodsEnum = {
    ItmVideoident: 'itm_videoident',
    NectAutoident: 'nect_autoident',
    PinletterIdent: 'pinletter_ident'
} as const;

export type UpdateOrderDtoAcceptedIdMethodsEnum = typeof UpdateOrderDtoAcceptedIdMethodsEnum[keyof typeof UpdateOrderDtoAcceptedIdMethodsEnum];
export const UpdateOrderDtoOfferedIdMethodsEnum = {
    ItmVideoident: 'itm_videoident',
    NectAutoident: 'nect_autoident',
    PinletterIdent: 'pinletter_ident'
} as const;

export type UpdateOrderDtoOfferedIdMethodsEnum = typeof UpdateOrderDtoOfferedIdMethodsEnum[keyof typeof UpdateOrderDtoOfferedIdMethodsEnum];
export const UpdateOrderDtoSelectedIdentProviderEnum = {
    CrefoFirmenwissen: 'crefo_firmenwissen',
    Admin: 'admin',
    ItmVideoident: 'itm_videoident',
    NectAutoident: 'nect_autoident',
    PinletterIdent: 'pinletter_ident',
    Did: 'did'
} as const;

export type UpdateOrderDtoSelectedIdentProviderEnum = typeof UpdateOrderDtoSelectedIdentProviderEnum[keyof typeof UpdateOrderDtoSelectedIdentProviderEnum];

/**
 * 
 * @export
 * @interface UpdateOrderStateDto
 */
export interface UpdateOrderStateDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateOrderStateDto
     */
    'step': UpdateOrderStateDtoStepEnum;
    /**
     * 
     * @type {OrderStepPayload}
     * @memberof UpdateOrderStateDto
     */
    'payload': OrderStepPayload;
}

export const UpdateOrderStateDtoStepEnum = {
    Created: 'created',
    Register: 'register',
    Login: 'login',
    SelectedCompany: 'selected-company',
    Request: 'request',
    RequestReopened: 'request-reopened',
    PersonVerified: 'person-verified',
    AuthorizedRepresentative: 'authorized-representative',
    CompanyAgent: 'company-agent',
    CredentialsSent: 'credentials-sent',
    CredentialsSendingError: 'credentials-sending-error',
    TimedOut: 'timed-out',
    ProcessCanceled: 'process-canceled',
    RequestsRejected: 'requests-rejected',
    AuthorizedRepresentativeManuallyIssued: 'authorized-representative-manually-issued',
    AuthorizedRepresentativeManuallyRejected: 'authorized-representative-manually-rejected',
    PendingIdentDataCorrection: 'pending-ident-data-correction',
    OrderIsDuplicate: 'order-is-duplicate'
} as const;

export type UpdateOrderStateDtoStepEnum = typeof UpdateOrderStateDtoStepEnum[keyof typeof UpdateOrderStateDtoStepEnum];

/**
 * 
 * @export
 * @interface UpdateRequestDto
 */
export interface UpdateRequestDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateRequestDto
     */
    'type': UpdateRequestDtoTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof UpdateRequestDto
     */
    'orderId'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateRequestDto
     */
    'dataSource'?: UpdateRequestDtoDataSourceEnum;
    /**
     * 
     * @type {string}
     * @memberof UpdateRequestDto
     */
    'state'?: UpdateRequestDtoStateEnum;
    /**
     * 
     * @type {string}
     * @memberof UpdateRequestDto
     */
    'stateReason'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateRequestDto
     */
    'comment'?: string;
}

export const UpdateRequestDtoTypeEnum = {
    Org: 'org',
    Person: 'person'
} as const;

export type UpdateRequestDtoTypeEnum = typeof UpdateRequestDtoTypeEnum[keyof typeof UpdateRequestDtoTypeEnum];
export const UpdateRequestDtoDataSourceEnum = {
    ItmVideoident: 'itm_videoident',
    NectAutoident: 'nect_autoident'
} as const;

export type UpdateRequestDtoDataSourceEnum = typeof UpdateRequestDtoDataSourceEnum[keyof typeof UpdateRequestDtoDataSourceEnum];
export const UpdateRequestDtoStateEnum = {
    Open: 'OPEN',
    Accepted: 'ACCEPTED',
    Rejected: 'REJECTED',
    Inprogress: 'INPROGRESS',
    CustomerPostprocessing: 'CUSTOMER_POSTPROCESSING',
    Closed: 'CLOSED'
} as const;

export type UpdateRequestDtoStateEnum = typeof UpdateRequestDtoStateEnum[keyof typeof UpdateRequestDtoStateEnum];

/**
 * 
 * @export
 * @interface Verifier
 */
export interface Verifier {
    /**
     * 
     * @type {string}
     * @memberof Verifier
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof Verifier
     */
    'verifierId': string;
    /**
     * 
     * @type {string}
     * @memberof Verifier
     */
    'apiKey': string;
    /**
     * 
     * @type {string}
     * @memberof Verifier
     */
    'name': string;
    /**
     * 
     * @type {object}
     * @memberof Verifier
     */
    'dataEnabler'?: object;
    /**
     * 
     * @type {string}
     * @memberof Verifier
     */
    'logoUrl'?: string;
}
/**
 * 
 * @export
 * @interface VerifierResponse
 */
export interface VerifierResponse {
    /**
     * 
     * @type {Array<Verifier>}
     * @memberof VerifierResponse
     */
    'data': Array<Verifier>;
    /**
     * 
     * @type {number}
     * @memberof VerifierResponse
     */
    'totalCount': number;
}
/**
 * 
 * @export
 * @interface VerifierWebhookResponse
 */
export interface VerifierWebhookResponse {
    /**
     * The unique identifier for this order.
     * @type {string}
     * @memberof VerifierWebhookResponse
     */
    'orderId': string;
    /**
     * 
     * @type {VerifierWebhookResponseMeta}
     * @memberof VerifierWebhookResponse
     */
    'meta': VerifierWebhookResponseMeta;
    /**
     * 
     * @type {VerifierWebhookResponsePerson}
     * @memberof VerifierWebhookResponse
     */
    'person': VerifierWebhookResponsePerson;
    /**
     * Describes whether or not the person has the role of an authorized representative for this company.
     * @type {boolean}
     * @memberof VerifierWebhookResponse
     */
    'authorizedRepresentative': boolean;
    /**
     * Describes whether or not the person has the role of a company agent for this company.
     * @type {boolean}
     * @memberof VerifierWebhookResponse
     */
    'verifiedAgent': boolean;
    /**
     * 
     * @type {VerifierWebhookResponseOrg}
     * @memberof VerifierWebhookResponse
     */
    'org': VerifierWebhookResponseOrg;
}
/**
 * Meta information for this order webhook response.
 * @export
 * @interface VerifierWebhookResponseMeta
 */
export interface VerifierWebhookResponseMeta {
    /**
     * The id of the verifier this order webhook response belongs to.
     * @type {string}
     * @memberof VerifierWebhookResponseMeta
     */
    'verifier': string;
    /**
     * An arbitrary data structure provided by the verifier while creating the order.
     * @type {object}
     * @memberof VerifierWebhookResponseMeta
     */
    'verifierRef': object;
    /**
     * 
     * @type {VerifierWebhookResponseMetaPerson}
     * @memberof VerifierWebhookResponseMeta
     */
    'person': VerifierWebhookResponseMetaPerson;
    /**
     * 
     * @type {VerifierWebhookResponseMetaAuthorizedRepresentative}
     * @memberof VerifierWebhookResponseMeta
     */
    'authorizedRepresentative'?: VerifierWebhookResponseMetaAuthorizedRepresentative;
    /**
     * 
     * @type {VerifierWebhookResponseMetaVerifiedAgent}
     * @memberof VerifierWebhookResponseMeta
     */
    'verifiedAgent'?: VerifierWebhookResponseMetaVerifiedAgent;
    /**
     * 
     * @type {VerifierWebhookResponseMetaOrg}
     * @memberof VerifierWebhookResponseMeta
     */
    'org': VerifierWebhookResponseMetaOrg;
}
/**
 * Meta data the relation \'authorized representative\' between the person and the company.
 * @export
 * @interface VerifierWebhookResponseMetaAuthorizedRepresentative
 */
export interface VerifierWebhookResponseMetaAuthorizedRepresentative {
    /**
     * Timestamp (unix epoch) when this information was created.
     * @type {number}
     * @memberof VerifierWebhookResponseMetaAuthorizedRepresentative
     */
    'issuedAt': number;
    /**
     * The user (DID) to that this information regards to.
     * @type {string}
     * @memberof VerifierWebhookResponseMetaAuthorizedRepresentative
     */
    'person': string;
    /**
     * The company (DID) to that this information regards to.
     * @type {string}
     * @memberof VerifierWebhookResponseMetaAuthorizedRepresentative
     */
    'org': string;
    /**
     * This endpoint (url) provided by CrefoTrust can be used to check the current staus of the provided information.
     * @type {string}
     * @memberof VerifierWebhookResponseMetaAuthorizedRepresentative
     */
    'status': string;
    /**
     * Issuer (DID) of the credential data.
     * @type {string}
     * @memberof VerifierWebhookResponseMetaAuthorizedRepresentative
     */
    'issuer': string;
}
/**
 * Meta data for all company related data.
 * @export
 * @interface VerifierWebhookResponseMetaOrg
 */
export interface VerifierWebhookResponseMetaOrg {
    /**
     * The did of the company.
     * @type {string}
     * @memberof VerifierWebhookResponseMetaOrg
     */
    'id'?: string;
    /**
     * 
     * @type {OrgMetaCredentials}
     * @memberof VerifierWebhookResponseMetaOrg
     */
    'credentials'?: OrgMetaCredentials;
}
/**
 * Meta data for all person related data.
 * @export
 * @interface VerifierWebhookResponseMetaPerson
 */
export interface VerifierWebhookResponseMetaPerson {
    /**
     * The did of the person.
     * @type {string}
     * @memberof VerifierWebhookResponseMetaPerson
     */
    'id'?: string;
    /**
     * 
     * @type {PersonMetaCredentials}
     * @memberof VerifierWebhookResponseMetaPerson
     */
    'credentials'?: PersonMetaCredentials;
}
/**
 * Meta data the relation \'authorized representative\' between the person and the company.
 * @export
 * @interface VerifierWebhookResponseMetaVerifiedAgent
 */
export interface VerifierWebhookResponseMetaVerifiedAgent {
    /**
     * Timestamp (unix epoch) when this information was created.
     * @type {number}
     * @memberof VerifierWebhookResponseMetaVerifiedAgent
     */
    'issuedAt': number;
    /**
     * The user (DID) to that this information regards to.
     * @type {string}
     * @memberof VerifierWebhookResponseMetaVerifiedAgent
     */
    'person': string;
    /**
     * The company (DID) to that this information regards to.
     * @type {string}
     * @memberof VerifierWebhookResponseMetaVerifiedAgent
     */
    'org': string;
    /**
     * This endpoint (url) provided by CrefoTrust can be used to check the current staus of the provided information.
     * @type {string}
     * @memberof VerifierWebhookResponseMetaVerifiedAgent
     */
    'status': string;
    /**
     * Issuer (DID) of the credential data.
     * @type {string}
     * @memberof VerifierWebhookResponseMetaVerifiedAgent
     */
    'issuer': string;
}
/**
 * All company related information.
 * @export
 * @interface VerifierWebhookResponseOrg
 */
export interface VerifierWebhookResponseOrg {
    /**
     * The legal name of the company.
     * @type {string}
     * @memberof VerifierWebhookResponseOrg
     */
    'legalName'?: string;
    /**
     * The legal form of the company.
     * @type {string}
     * @memberof VerifierWebhookResponseOrg
     */
    'legalForm'?: string;
    /**
     * 
     * @type {VerifierWebhookResponseOrgAddress}
     * @memberof VerifierWebhookResponseOrg
     */
    'address'?: VerifierWebhookResponseOrgAddress;
    /**
     * The crefoId of the company.
     * @type {string}
     * @memberof VerifierWebhookResponseOrg
     */
    'crefoId'?: string;
}
/**
 * The address of the company.
 * @export
 * @interface VerifierWebhookResponseOrgAddress
 */
export interface VerifierWebhookResponseOrgAddress {
    /**
     * Street and number.
     * @type {string}
     * @memberof VerifierWebhookResponseOrgAddress
     */
    'streetAddress': string;
    /**
     * Postal code of the city.
     * @type {string}
     * @memberof VerifierWebhookResponseOrgAddress
     */
    'postalCode': string;
    /**
     * Name of the city.
     * @type {string}
     * @memberof VerifierWebhookResponseOrgAddress
     */
    'addressLocality': string;
    /**
     * ISO code of the country.
     * @type {string}
     * @memberof VerifierWebhookResponseOrgAddress
     */
    'addressCountry': string;
}
/**
 * All person related information.
 * @export
 * @interface VerifierWebhookResponsePerson
 */
export interface VerifierWebhookResponsePerson {
    /**
     * The crid of the person.
     * @type {string}
     * @memberof VerifierWebhookResponsePerson
     */
    'crid'?: string;
    /**
     * The crefoId of the person.
     * @type {string}
     * @memberof VerifierWebhookResponsePerson
     */
    'crefoId'?: string;
    /**
     * 
     * @type {VerifierWebhookResponsePersonAddress}
     * @memberof VerifierWebhookResponsePerson
     */
    'address'?: VerifierWebhookResponsePersonAddress;
    /**
     * 
     * @type {VerifierWebhookResponsePersonName}
     * @memberof VerifierWebhookResponsePerson
     */
    'name'?: VerifierWebhookResponsePersonName;
    /**
     * The birthdate of the person.
     * @type {string}
     * @memberof VerifierWebhookResponsePerson
     */
    'birthdate'?: string;
    /**
     * The nationality of the person.
     * @type {string}
     * @memberof VerifierWebhookResponsePerson
     */
    'nationality'?: string;
    /**
     * The person is an agent for the company.
     * @type {string}
     * @memberof VerifierWebhookResponsePerson
     */
    'companyAgent'?: string;
}
/**
 * The address of the person.
 * @export
 * @interface VerifierWebhookResponsePersonAddress
 */
export interface VerifierWebhookResponsePersonAddress {
    /**
     * Street and number.
     * @type {string}
     * @memberof VerifierWebhookResponsePersonAddress
     */
    'streetAddress': string;
    /**
     * Postal code of the city.
     * @type {string}
     * @memberof VerifierWebhookResponsePersonAddress
     */
    'postalCode': string;
    /**
     * Name of the city.
     * @type {string}
     * @memberof VerifierWebhookResponsePersonAddress
     */
    'addressLocality': string;
    /**
     * ISO code of the country.
     * @type {string}
     * @memberof VerifierWebhookResponsePersonAddress
     */
    'addressCountry': string;
}
/**
 * The name of the person.
 * @export
 * @interface VerifierWebhookResponsePersonName
 */
export interface VerifierWebhookResponsePersonName {
    /**
     * 
     * @type {string}
     * @memberof VerifierWebhookResponsePersonName
     */
    'familyName': string;
    /**
     * 
     * @type {string}
     * @memberof VerifierWebhookResponsePersonName
     */
    'givenName': string;
}

/**
 * AdminApi - axios parameter creator
 * @export
 */
export const AdminApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} id 
         * @param {AcceptOrderDto} acceptOrderDto 
         * @param {string} [xCrefotrustSystemId] System key used for internal self-service.
         * @param {string} [xCrefotrustApiKey] API key used for internal self-service.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGatewayControllerAcceptRequest: async (id: string, acceptOrderDto: AcceptOrderDto, xCrefotrustSystemId?: string, xCrefotrustApiKey?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminGatewayControllerAcceptRequest', 'id', id)
            // verify required parameter 'acceptOrderDto' is not null or undefined
            assertParamExists('adminGatewayControllerAcceptRequest', 'acceptOrderDto', acceptOrderDto)
            const localVarPath = `/api/admin/requests/{id}/accept`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xCrefotrustSystemId != null) {
                localVarHeaderParameter['x-crefotrust-system-id'] = String(xCrefotrustSystemId);
            }

            if (xCrefotrustApiKey != null) {
                localVarHeaderParameter['x-crefotrust-api-key'] = String(xCrefotrustApiKey);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(acceptOrderDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns the a list of accounts.
         * @param {string} [start] 
         * @param {string} [end] 
         * @param {string} [email] 
         * @param {string} [accountVerified] 
         * @param {string} [accountUsername] 
         * @param {string} [credentialsPayloadGivenName] 
         * @param {string} [credentialsPayloadFamilyName] 
         * @param {string} [credentialsPayloadCrefoId] 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {string} [order] 
         * @param {string} [sort] 
         * @param {string} [xCrefotrustSystemId] System key used for internal self-service.
         * @param {string} [xCrefotrustApiKey] API key used for internal self-service.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGatewayControllerFindAccounts: async (start?: string, end?: string, email?: string, accountVerified?: string, accountUsername?: string, credentialsPayloadGivenName?: string, credentialsPayloadFamilyName?: string, credentialsPayloadCrefoId?: string, from?: string, to?: string, order?: string, sort?: string, xCrefotrustSystemId?: string, xCrefotrustApiKey?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/admin/accounts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (start !== undefined) {
                localVarQueryParameter['start'] = start;
            }

            if (end !== undefined) {
                localVarQueryParameter['end'] = end;
            }

            if (email !== undefined) {
                localVarQueryParameter['email'] = email;
            }

            if (accountVerified !== undefined) {
                localVarQueryParameter['account.verified'] = accountVerified;
            }

            if (accountUsername !== undefined) {
                localVarQueryParameter['account.username'] = accountUsername;
            }

            if (credentialsPayloadGivenName !== undefined) {
                localVarQueryParameter['credentials.payload.givenName'] = credentialsPayloadGivenName;
            }

            if (credentialsPayloadFamilyName !== undefined) {
                localVarQueryParameter['credentials.payload.familyName'] = credentialsPayloadFamilyName;
            }

            if (credentialsPayloadCrefoId !== undefined) {
                localVarQueryParameter['credentials.payload.crefoId'] = credentialsPayloadCrefoId;
            }

            if (from !== undefined) {
                localVarQueryParameter['from'] = from;
            }

            if (to !== undefined) {
                localVarQueryParameter['to'] = to;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (xCrefotrustSystemId != null) {
                localVarHeaderParameter['x-crefotrust-system-id'] = String(xCrefotrustSystemId);
            }

            if (xCrefotrustApiKey != null) {
                localVarHeaderParameter['x-crefotrust-api-key'] = String(xCrefotrustApiKey);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns the an order based on the provided id.
         * @param {string} [start] 
         * @param {string} [end] 
         * @param {string} [crefoId] 
         * @param {string} [companyLegalName] 
         * @param {string} [accountUsername] 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {string} [order] 
         * @param {string} [sort] 
         * @param {string} [xCrefotrustSystemId] System key used for internal self-service.
         * @param {string} [xCrefotrustApiKey] API key used for internal self-service.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGatewayControllerFindCompanies: async (start?: string, end?: string, crefoId?: string, companyLegalName?: string, accountUsername?: string, from?: string, to?: string, order?: string, sort?: string, xCrefotrustSystemId?: string, xCrefotrustApiKey?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/admin/companies`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (start !== undefined) {
                localVarQueryParameter['start'] = start;
            }

            if (end !== undefined) {
                localVarQueryParameter['end'] = end;
            }

            if (crefoId !== undefined) {
                localVarQueryParameter['crefoId'] = crefoId;
            }

            if (companyLegalName !== undefined) {
                localVarQueryParameter['company.legalName'] = companyLegalName;
            }

            if (accountUsername !== undefined) {
                localVarQueryParameter['account.username'] = accountUsername;
            }

            if (from !== undefined) {
                localVarQueryParameter['from'] = from;
            }

            if (to !== undefined) {
                localVarQueryParameter['to'] = to;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (xCrefotrustSystemId != null) {
                localVarHeaderParameter['x-crefotrust-system-id'] = String(xCrefotrustSystemId);
            }

            if (xCrefotrustApiKey != null) {
                localVarHeaderParameter['x-crefotrust-api-key'] = String(xCrefotrustApiKey);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns the an order based on the provided filter.
         * @param {string} [start] 
         * @param {string} [end] 
         * @param {string} [id] 
         * @param {string} [companyLegalName] 
         * @param {string} [personGivenName] 
         * @param {string} [personFamilyName] 
         * @param {string} [optionsVerifierName] 
         * @param {string} [optionsUseCase] 
         * @param {string} [currentStateCode] 
         * @param {string} [accountUsername] 
         * @param {string} [completed] 
         * @param {string} [hasErrorLog] 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {string} [order] 
         * @param {string} [sort] 
         * @param {string} [xCrefotrustSystemId] System key used for internal self-service.
         * @param {string} [xCrefotrustApiKey] API key used for internal self-service.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGatewayControllerFindOrders: async (start?: string, end?: string, id?: string, companyLegalName?: string, personGivenName?: string, personFamilyName?: string, optionsVerifierName?: string, optionsUseCase?: string, currentStateCode?: string, accountUsername?: string, completed?: string, hasErrorLog?: string, from?: string, to?: string, order?: string, sort?: string, xCrefotrustSystemId?: string, xCrefotrustApiKey?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/admin/orders`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (start !== undefined) {
                localVarQueryParameter['start'] = start;
            }

            if (end !== undefined) {
                localVarQueryParameter['end'] = end;
            }

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            if (companyLegalName !== undefined) {
                localVarQueryParameter['company.legalName'] = companyLegalName;
            }

            if (personGivenName !== undefined) {
                localVarQueryParameter['person.givenName'] = personGivenName;
            }

            if (personFamilyName !== undefined) {
                localVarQueryParameter['person.familyName'] = personFamilyName;
            }

            if (optionsVerifierName !== undefined) {
                localVarQueryParameter['options.verifierName'] = optionsVerifierName;
            }

            if (optionsUseCase !== undefined) {
                localVarQueryParameter['options.useCase'] = optionsUseCase;
            }

            if (currentStateCode !== undefined) {
                localVarQueryParameter['currentState.code'] = currentStateCode;
            }

            if (accountUsername !== undefined) {
                localVarQueryParameter['account.username'] = accountUsername;
            }

            if (completed !== undefined) {
                localVarQueryParameter['completed'] = completed;
            }

            if (hasErrorLog !== undefined) {
                localVarQueryParameter['hasErrorLog'] = hasErrorLog;
            }

            if (from !== undefined) {
                localVarQueryParameter['from'] = from;
            }

            if (to !== undefined) {
                localVarQueryParameter['to'] = to;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (xCrefotrustSystemId != null) {
                localVarHeaderParameter['x-crefotrust-system-id'] = String(xCrefotrustSystemId);
            }

            if (xCrefotrustApiKey != null) {
                localVarHeaderParameter['x-crefotrust-api-key'] = String(xCrefotrustApiKey);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [start] 
         * @param {string} [end] 
         * @param {string} [name] 
         * @param {string} [verifierId] 
         * @param {string} [order] 
         * @param {string} [sort] 
         * @param {string} [xCrefotrustSystemId] System key used for internal self-service.
         * @param {string} [xCrefotrustApiKey] API key used for internal self-service.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGatewayControllerFindVerifiers: async (start?: string, end?: string, name?: string, verifierId?: string, order?: string, sort?: string, xCrefotrustSystemId?: string, xCrefotrustApiKey?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/admin/verifiers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (start !== undefined) {
                localVarQueryParameter['start'] = start;
            }

            if (end !== undefined) {
                localVarQueryParameter['end'] = end;
            }

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (verifierId !== undefined) {
                localVarQueryParameter['verifierId'] = verifierId;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (xCrefotrustSystemId != null) {
                localVarHeaderParameter['x-crefotrust-system-id'] = String(xCrefotrustSystemId);
            }

            if (xCrefotrustApiKey != null) {
                localVarHeaderParameter['x-crefotrust-api-key'] = String(xCrefotrustApiKey);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {RejectOrderDto} rejectOrderDto 
         * @param {string} [xCrefotrustSystemId] System key used for internal self-service.
         * @param {string} [xCrefotrustApiKey] API key used for internal self-service.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGatewayControllerRejectRequest: async (id: string, rejectOrderDto: RejectOrderDto, xCrefotrustSystemId?: string, xCrefotrustApiKey?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminGatewayControllerRejectRequest', 'id', id)
            // verify required parameter 'rejectOrderDto' is not null or undefined
            assertParamExists('adminGatewayControllerRejectRequest', 'rejectOrderDto', rejectOrderDto)
            const localVarPath = `/api/admin/requests/{id}/reject`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xCrefotrustSystemId != null) {
                localVarHeaderParameter['x-crefotrust-system-id'] = String(xCrefotrustSystemId);
            }

            if (xCrefotrustApiKey != null) {
                localVarHeaderParameter['x-crefotrust-api-key'] = String(xCrefotrustApiKey);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(rejectOrderDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminApi - functional programming interface
 * @export
 */
export const AdminApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} id 
         * @param {AcceptOrderDto} acceptOrderDto 
         * @param {string} [xCrefotrustSystemId] System key used for internal self-service.
         * @param {string} [xCrefotrustApiKey] API key used for internal self-service.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminGatewayControllerAcceptRequest(id: string, acceptOrderDto: AcceptOrderDto, xCrefotrustSystemId?: string, xCrefotrustApiKey?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminGatewayControllerAcceptRequest(id, acceptOrderDto, xCrefotrustSystemId, xCrefotrustApiKey, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns the a list of accounts.
         * @param {string} [start] 
         * @param {string} [end] 
         * @param {string} [email] 
         * @param {string} [accountVerified] 
         * @param {string} [accountUsername] 
         * @param {string} [credentialsPayloadGivenName] 
         * @param {string} [credentialsPayloadFamilyName] 
         * @param {string} [credentialsPayloadCrefoId] 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {string} [order] 
         * @param {string} [sort] 
         * @param {string} [xCrefotrustSystemId] System key used for internal self-service.
         * @param {string} [xCrefotrustApiKey] API key used for internal self-service.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminGatewayControllerFindAccounts(start?: string, end?: string, email?: string, accountVerified?: string, accountUsername?: string, credentialsPayloadGivenName?: string, credentialsPayloadFamilyName?: string, credentialsPayloadCrefoId?: string, from?: string, to?: string, order?: string, sort?: string, xCrefotrustSystemId?: string, xCrefotrustApiKey?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminAccountsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminGatewayControllerFindAccounts(start, end, email, accountVerified, accountUsername, credentialsPayloadGivenName, credentialsPayloadFamilyName, credentialsPayloadCrefoId, from, to, order, sort, xCrefotrustSystemId, xCrefotrustApiKey, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns the an order based on the provided id.
         * @param {string} [start] 
         * @param {string} [end] 
         * @param {string} [crefoId] 
         * @param {string} [companyLegalName] 
         * @param {string} [accountUsername] 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {string} [order] 
         * @param {string} [sort] 
         * @param {string} [xCrefotrustSystemId] System key used for internal self-service.
         * @param {string} [xCrefotrustApiKey] API key used for internal self-service.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminGatewayControllerFindCompanies(start?: string, end?: string, crefoId?: string, companyLegalName?: string, accountUsername?: string, from?: string, to?: string, order?: string, sort?: string, xCrefotrustSystemId?: string, xCrefotrustApiKey?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminCompaniesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminGatewayControllerFindCompanies(start, end, crefoId, companyLegalName, accountUsername, from, to, order, sort, xCrefotrustSystemId, xCrefotrustApiKey, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns the an order based on the provided filter.
         * @param {string} [start] 
         * @param {string} [end] 
         * @param {string} [id] 
         * @param {string} [companyLegalName] 
         * @param {string} [personGivenName] 
         * @param {string} [personFamilyName] 
         * @param {string} [optionsVerifierName] 
         * @param {string} [optionsUseCase] 
         * @param {string} [currentStateCode] 
         * @param {string} [accountUsername] 
         * @param {string} [completed] 
         * @param {string} [hasErrorLog] 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {string} [order] 
         * @param {string} [sort] 
         * @param {string} [xCrefotrustSystemId] System key used for internal self-service.
         * @param {string} [xCrefotrustApiKey] API key used for internal self-service.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminGatewayControllerFindOrders(start?: string, end?: string, id?: string, companyLegalName?: string, personGivenName?: string, personFamilyName?: string, optionsVerifierName?: string, optionsUseCase?: string, currentStateCode?: string, accountUsername?: string, completed?: string, hasErrorLog?: string, from?: string, to?: string, order?: string, sort?: string, xCrefotrustSystemId?: string, xCrefotrustApiKey?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ExtendedOrderResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminGatewayControllerFindOrders(start, end, id, companyLegalName, personGivenName, personFamilyName, optionsVerifierName, optionsUseCase, currentStateCode, accountUsername, completed, hasErrorLog, from, to, order, sort, xCrefotrustSystemId, xCrefotrustApiKey, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [start] 
         * @param {string} [end] 
         * @param {string} [name] 
         * @param {string} [verifierId] 
         * @param {string} [order] 
         * @param {string} [sort] 
         * @param {string} [xCrefotrustSystemId] System key used for internal self-service.
         * @param {string} [xCrefotrustApiKey] API key used for internal self-service.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminGatewayControllerFindVerifiers(start?: string, end?: string, name?: string, verifierId?: string, order?: string, sort?: string, xCrefotrustSystemId?: string, xCrefotrustApiKey?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminVerifiersResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminGatewayControllerFindVerifiers(start, end, name, verifierId, order, sort, xCrefotrustSystemId, xCrefotrustApiKey, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {RejectOrderDto} rejectOrderDto 
         * @param {string} [xCrefotrustSystemId] System key used for internal self-service.
         * @param {string} [xCrefotrustApiKey] API key used for internal self-service.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminGatewayControllerRejectRequest(id: string, rejectOrderDto: RejectOrderDto, xCrefotrustSystemId?: string, xCrefotrustApiKey?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminGatewayControllerRejectRequest(id, rejectOrderDto, xCrefotrustSystemId, xCrefotrustApiKey, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdminApi - factory interface
 * @export
 */
export const AdminApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminApiFp(configuration)
    return {
        /**
         * 
         * @param {string} id 
         * @param {AcceptOrderDto} acceptOrderDto 
         * @param {string} [xCrefotrustSystemId] System key used for internal self-service.
         * @param {string} [xCrefotrustApiKey] API key used for internal self-service.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGatewayControllerAcceptRequest(id: string, acceptOrderDto: AcceptOrderDto, xCrefotrustSystemId?: string, xCrefotrustApiKey?: string, options?: any): AxiosPromise<void> {
            return localVarFp.adminGatewayControllerAcceptRequest(id, acceptOrderDto, xCrefotrustSystemId, xCrefotrustApiKey, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns the a list of accounts.
         * @param {string} [start] 
         * @param {string} [end] 
         * @param {string} [email] 
         * @param {string} [accountVerified] 
         * @param {string} [accountUsername] 
         * @param {string} [credentialsPayloadGivenName] 
         * @param {string} [credentialsPayloadFamilyName] 
         * @param {string} [credentialsPayloadCrefoId] 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {string} [order] 
         * @param {string} [sort] 
         * @param {string} [xCrefotrustSystemId] System key used for internal self-service.
         * @param {string} [xCrefotrustApiKey] API key used for internal self-service.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGatewayControllerFindAccounts(start?: string, end?: string, email?: string, accountVerified?: string, accountUsername?: string, credentialsPayloadGivenName?: string, credentialsPayloadFamilyName?: string, credentialsPayloadCrefoId?: string, from?: string, to?: string, order?: string, sort?: string, xCrefotrustSystemId?: string, xCrefotrustApiKey?: string, options?: any): AxiosPromise<AdminAccountsResponse> {
            return localVarFp.adminGatewayControllerFindAccounts(start, end, email, accountVerified, accountUsername, credentialsPayloadGivenName, credentialsPayloadFamilyName, credentialsPayloadCrefoId, from, to, order, sort, xCrefotrustSystemId, xCrefotrustApiKey, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns the an order based on the provided id.
         * @param {string} [start] 
         * @param {string} [end] 
         * @param {string} [crefoId] 
         * @param {string} [companyLegalName] 
         * @param {string} [accountUsername] 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {string} [order] 
         * @param {string} [sort] 
         * @param {string} [xCrefotrustSystemId] System key used for internal self-service.
         * @param {string} [xCrefotrustApiKey] API key used for internal self-service.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGatewayControllerFindCompanies(start?: string, end?: string, crefoId?: string, companyLegalName?: string, accountUsername?: string, from?: string, to?: string, order?: string, sort?: string, xCrefotrustSystemId?: string, xCrefotrustApiKey?: string, options?: any): AxiosPromise<AdminCompaniesResponse> {
            return localVarFp.adminGatewayControllerFindCompanies(start, end, crefoId, companyLegalName, accountUsername, from, to, order, sort, xCrefotrustSystemId, xCrefotrustApiKey, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns the an order based on the provided filter.
         * @param {string} [start] 
         * @param {string} [end] 
         * @param {string} [id] 
         * @param {string} [companyLegalName] 
         * @param {string} [personGivenName] 
         * @param {string} [personFamilyName] 
         * @param {string} [optionsVerifierName] 
         * @param {string} [optionsUseCase] 
         * @param {string} [currentStateCode] 
         * @param {string} [accountUsername] 
         * @param {string} [completed] 
         * @param {string} [hasErrorLog] 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {string} [order] 
         * @param {string} [sort] 
         * @param {string} [xCrefotrustSystemId] System key used for internal self-service.
         * @param {string} [xCrefotrustApiKey] API key used for internal self-service.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGatewayControllerFindOrders(start?: string, end?: string, id?: string, companyLegalName?: string, personGivenName?: string, personFamilyName?: string, optionsVerifierName?: string, optionsUseCase?: string, currentStateCode?: string, accountUsername?: string, completed?: string, hasErrorLog?: string, from?: string, to?: string, order?: string, sort?: string, xCrefotrustSystemId?: string, xCrefotrustApiKey?: string, options?: any): AxiosPromise<ExtendedOrderResponse> {
            return localVarFp.adminGatewayControllerFindOrders(start, end, id, companyLegalName, personGivenName, personFamilyName, optionsVerifierName, optionsUseCase, currentStateCode, accountUsername, completed, hasErrorLog, from, to, order, sort, xCrefotrustSystemId, xCrefotrustApiKey, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [start] 
         * @param {string} [end] 
         * @param {string} [name] 
         * @param {string} [verifierId] 
         * @param {string} [order] 
         * @param {string} [sort] 
         * @param {string} [xCrefotrustSystemId] System key used for internal self-service.
         * @param {string} [xCrefotrustApiKey] API key used for internal self-service.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGatewayControllerFindVerifiers(start?: string, end?: string, name?: string, verifierId?: string, order?: string, sort?: string, xCrefotrustSystemId?: string, xCrefotrustApiKey?: string, options?: any): AxiosPromise<AdminVerifiersResponse> {
            return localVarFp.adminGatewayControllerFindVerifiers(start, end, name, verifierId, order, sort, xCrefotrustSystemId, xCrefotrustApiKey, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {RejectOrderDto} rejectOrderDto 
         * @param {string} [xCrefotrustSystemId] System key used for internal self-service.
         * @param {string} [xCrefotrustApiKey] API key used for internal self-service.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGatewayControllerRejectRequest(id: string, rejectOrderDto: RejectOrderDto, xCrefotrustSystemId?: string, xCrefotrustApiKey?: string, options?: any): AxiosPromise<void> {
            return localVarFp.adminGatewayControllerRejectRequest(id, rejectOrderDto, xCrefotrustSystemId, xCrefotrustApiKey, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AdminApi - object-oriented interface
 * @export
 * @class AdminApi
 * @extends {BaseAPI}
 */
export class AdminApi extends BaseAPI {
    /**
     * 
     * @param {string} id 
     * @param {AcceptOrderDto} acceptOrderDto 
     * @param {string} [xCrefotrustSystemId] System key used for internal self-service.
     * @param {string} [xCrefotrustApiKey] API key used for internal self-service.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminGatewayControllerAcceptRequest(id: string, acceptOrderDto: AcceptOrderDto, xCrefotrustSystemId?: string, xCrefotrustApiKey?: string, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).adminGatewayControllerAcceptRequest(id, acceptOrderDto, xCrefotrustSystemId, xCrefotrustApiKey, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns the a list of accounts.
     * @param {string} [start] 
     * @param {string} [end] 
     * @param {string} [email] 
     * @param {string} [accountVerified] 
     * @param {string} [accountUsername] 
     * @param {string} [credentialsPayloadGivenName] 
     * @param {string} [credentialsPayloadFamilyName] 
     * @param {string} [credentialsPayloadCrefoId] 
     * @param {string} [from] 
     * @param {string} [to] 
     * @param {string} [order] 
     * @param {string} [sort] 
     * @param {string} [xCrefotrustSystemId] System key used for internal self-service.
     * @param {string} [xCrefotrustApiKey] API key used for internal self-service.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminGatewayControllerFindAccounts(start?: string, end?: string, email?: string, accountVerified?: string, accountUsername?: string, credentialsPayloadGivenName?: string, credentialsPayloadFamilyName?: string, credentialsPayloadCrefoId?: string, from?: string, to?: string, order?: string, sort?: string, xCrefotrustSystemId?: string, xCrefotrustApiKey?: string, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).adminGatewayControllerFindAccounts(start, end, email, accountVerified, accountUsername, credentialsPayloadGivenName, credentialsPayloadFamilyName, credentialsPayloadCrefoId, from, to, order, sort, xCrefotrustSystemId, xCrefotrustApiKey, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns the an order based on the provided id.
     * @param {string} [start] 
     * @param {string} [end] 
     * @param {string} [crefoId] 
     * @param {string} [companyLegalName] 
     * @param {string} [accountUsername] 
     * @param {string} [from] 
     * @param {string} [to] 
     * @param {string} [order] 
     * @param {string} [sort] 
     * @param {string} [xCrefotrustSystemId] System key used for internal self-service.
     * @param {string} [xCrefotrustApiKey] API key used for internal self-service.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminGatewayControllerFindCompanies(start?: string, end?: string, crefoId?: string, companyLegalName?: string, accountUsername?: string, from?: string, to?: string, order?: string, sort?: string, xCrefotrustSystemId?: string, xCrefotrustApiKey?: string, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).adminGatewayControllerFindCompanies(start, end, crefoId, companyLegalName, accountUsername, from, to, order, sort, xCrefotrustSystemId, xCrefotrustApiKey, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns the an order based on the provided filter.
     * @param {string} [start] 
     * @param {string} [end] 
     * @param {string} [id] 
     * @param {string} [companyLegalName] 
     * @param {string} [personGivenName] 
     * @param {string} [personFamilyName] 
     * @param {string} [optionsVerifierName] 
     * @param {string} [optionsUseCase] 
     * @param {string} [currentStateCode] 
     * @param {string} [accountUsername] 
     * @param {string} [completed] 
     * @param {string} [hasErrorLog] 
     * @param {string} [from] 
     * @param {string} [to] 
     * @param {string} [order] 
     * @param {string} [sort] 
     * @param {string} [xCrefotrustSystemId] System key used for internal self-service.
     * @param {string} [xCrefotrustApiKey] API key used for internal self-service.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminGatewayControllerFindOrders(start?: string, end?: string, id?: string, companyLegalName?: string, personGivenName?: string, personFamilyName?: string, optionsVerifierName?: string, optionsUseCase?: string, currentStateCode?: string, accountUsername?: string, completed?: string, hasErrorLog?: string, from?: string, to?: string, order?: string, sort?: string, xCrefotrustSystemId?: string, xCrefotrustApiKey?: string, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).adminGatewayControllerFindOrders(start, end, id, companyLegalName, personGivenName, personFamilyName, optionsVerifierName, optionsUseCase, currentStateCode, accountUsername, completed, hasErrorLog, from, to, order, sort, xCrefotrustSystemId, xCrefotrustApiKey, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [start] 
     * @param {string} [end] 
     * @param {string} [name] 
     * @param {string} [verifierId] 
     * @param {string} [order] 
     * @param {string} [sort] 
     * @param {string} [xCrefotrustSystemId] System key used for internal self-service.
     * @param {string} [xCrefotrustApiKey] API key used for internal self-service.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminGatewayControllerFindVerifiers(start?: string, end?: string, name?: string, verifierId?: string, order?: string, sort?: string, xCrefotrustSystemId?: string, xCrefotrustApiKey?: string, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).adminGatewayControllerFindVerifiers(start, end, name, verifierId, order, sort, xCrefotrustSystemId, xCrefotrustApiKey, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {RejectOrderDto} rejectOrderDto 
     * @param {string} [xCrefotrustSystemId] System key used for internal self-service.
     * @param {string} [xCrefotrustApiKey] API key used for internal self-service.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminGatewayControllerRejectRequest(id: string, rejectOrderDto: RejectOrderDto, xCrefotrustSystemId?: string, xCrefotrustApiKey?: string, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).adminGatewayControllerRejectRequest(id, rejectOrderDto, xCrefotrustSystemId, xCrefotrustApiKey, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CredentialsApi - axios parameter creator
 * @export
 */
export const CredentialsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get the current validity state of a credential.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        credentialControllerGetCredentialState: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('credentialControllerGetCredentialState', 'id', id)
            const localVarPath = `/api/credentials/{id}/status`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the current validity state of a credential.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        credentialControllerGetCredentialState_1: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('credentialControllerGetCredentialState_1', 'id', id)
            const localVarPath = `/api/credentials/{id}/status`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [xCrefotrustSystemId] 
         * @param {string} [xCrefotrustApiKey] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        credentialControllerGetCredentialsByFilter: async (xCrefotrustSystemId?: string, xCrefotrustApiKey?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/credentials`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xCrefotrustSystemId != null) {
                localVarHeaderParameter['x-crefotrust-system-id'] = String(xCrefotrustSystemId);
            }

            if (xCrefotrustApiKey != null) {
                localVarHeaderParameter['x-crefotrust-api-key'] = String(xCrefotrustApiKey);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<string>} requestBody 
         * @param {string} [xCrefotrustSystemId] 
         * @param {string} [xCrefotrustApiKey] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        credentialControllerIssueCredentials: async (requestBody: Array<string>, xCrefotrustSystemId?: string, xCrefotrustApiKey?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('credentialControllerIssueCredentials', 'requestBody', requestBody)
            const localVarPath = `/api/credentials`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xCrefotrustSystemId != null) {
                localVarHeaderParameter['x-crefotrust-system-id'] = String(xCrefotrustSystemId);
            }

            if (xCrefotrustApiKey != null) {
                localVarHeaderParameter['x-crefotrust-api-key'] = String(xCrefotrustApiKey);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {RevokeCredentialsDto} revokeCredentialsDto 
         * @param {string} [xCrefotrustSystemId] 
         * @param {string} [xCrefotrustApiKey] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        credentialControllerRevokeCredentialsPublic: async (revokeCredentialsDto: RevokeCredentialsDto, xCrefotrustSystemId?: string, xCrefotrustApiKey?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'revokeCredentialsDto' is not null or undefined
            assertParamExists('credentialControllerRevokeCredentialsPublic', 'revokeCredentialsDto', revokeCredentialsDto)
            const localVarPath = `/api/credentials/revoke`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xCrefotrustSystemId != null) {
                localVarHeaderParameter['x-crefotrust-system-id'] = String(xCrefotrustSystemId);
            }

            if (xCrefotrustApiKey != null) {
                localVarHeaderParameter['x-crefotrust-api-key'] = String(xCrefotrustApiKey);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(revokeCredentialsDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CredentialsApi - functional programming interface
 * @export
 */
export const CredentialsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CredentialsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get the current validity state of a credential.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async credentialControllerGetCredentialState(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.credentialControllerGetCredentialState(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get the current validity state of a credential.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async credentialControllerGetCredentialState_1(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.credentialControllerGetCredentialState_1(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [xCrefotrustSystemId] 
         * @param {string} [xCrefotrustApiKey] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async credentialControllerGetCredentialsByFilter(xCrefotrustSystemId?: string, xCrefotrustApiKey?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.credentialControllerGetCredentialsByFilter(xCrefotrustSystemId, xCrefotrustApiKey, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {Array<string>} requestBody 
         * @param {string} [xCrefotrustSystemId] 
         * @param {string} [xCrefotrustApiKey] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async credentialControllerIssueCredentials(requestBody: Array<string>, xCrefotrustSystemId?: string, xCrefotrustApiKey?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.credentialControllerIssueCredentials(requestBody, xCrefotrustSystemId, xCrefotrustApiKey, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {RevokeCredentialsDto} revokeCredentialsDto 
         * @param {string} [xCrefotrustSystemId] 
         * @param {string} [xCrefotrustApiKey] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async credentialControllerRevokeCredentialsPublic(revokeCredentialsDto: RevokeCredentialsDto, xCrefotrustSystemId?: string, xCrefotrustApiKey?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.credentialControllerRevokeCredentialsPublic(revokeCredentialsDto, xCrefotrustSystemId, xCrefotrustApiKey, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CredentialsApi - factory interface
 * @export
 */
export const CredentialsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CredentialsApiFp(configuration)
    return {
        /**
         * 
         * @summary Get the current validity state of a credential.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        credentialControllerGetCredentialState(id: string, options?: any): AxiosPromise<string> {
            return localVarFp.credentialControllerGetCredentialState(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get the current validity state of a credential.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        credentialControllerGetCredentialState_1(id: string, options?: any): AxiosPromise<string> {
            return localVarFp.credentialControllerGetCredentialState_1(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [xCrefotrustSystemId] 
         * @param {string} [xCrefotrustApiKey] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        credentialControllerGetCredentialsByFilter(xCrefotrustSystemId?: string, xCrefotrustApiKey?: string, options?: any): AxiosPromise<void> {
            return localVarFp.credentialControllerGetCredentialsByFilter(xCrefotrustSystemId, xCrefotrustApiKey, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Array<string>} requestBody 
         * @param {string} [xCrefotrustSystemId] 
         * @param {string} [xCrefotrustApiKey] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        credentialControllerIssueCredentials(requestBody: Array<string>, xCrefotrustSystemId?: string, xCrefotrustApiKey?: string, options?: any): AxiosPromise<void> {
            return localVarFp.credentialControllerIssueCredentials(requestBody, xCrefotrustSystemId, xCrefotrustApiKey, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {RevokeCredentialsDto} revokeCredentialsDto 
         * @param {string} [xCrefotrustSystemId] 
         * @param {string} [xCrefotrustApiKey] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        credentialControllerRevokeCredentialsPublic(revokeCredentialsDto: RevokeCredentialsDto, xCrefotrustSystemId?: string, xCrefotrustApiKey?: string, options?: any): AxiosPromise<void> {
            return localVarFp.credentialControllerRevokeCredentialsPublic(revokeCredentialsDto, xCrefotrustSystemId, xCrefotrustApiKey, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CredentialsApi - object-oriented interface
 * @export
 * @class CredentialsApi
 * @extends {BaseAPI}
 */
export class CredentialsApi extends BaseAPI {
    /**
     * 
     * @summary Get the current validity state of a credential.
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CredentialsApi
     */
    public credentialControllerGetCredentialState(id: string, options?: AxiosRequestConfig) {
        return CredentialsApiFp(this.configuration).credentialControllerGetCredentialState(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get the current validity state of a credential.
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CredentialsApi
     */
    public credentialControllerGetCredentialState_1(id: string, options?: AxiosRequestConfig) {
        return CredentialsApiFp(this.configuration).credentialControllerGetCredentialState_1(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [xCrefotrustSystemId] 
     * @param {string} [xCrefotrustApiKey] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CredentialsApi
     */
    public credentialControllerGetCredentialsByFilter(xCrefotrustSystemId?: string, xCrefotrustApiKey?: string, options?: AxiosRequestConfig) {
        return CredentialsApiFp(this.configuration).credentialControllerGetCredentialsByFilter(xCrefotrustSystemId, xCrefotrustApiKey, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Array<string>} requestBody 
     * @param {string} [xCrefotrustSystemId] 
     * @param {string} [xCrefotrustApiKey] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CredentialsApi
     */
    public credentialControllerIssueCredentials(requestBody: Array<string>, xCrefotrustSystemId?: string, xCrefotrustApiKey?: string, options?: AxiosRequestConfig) {
        return CredentialsApiFp(this.configuration).credentialControllerIssueCredentials(requestBody, xCrefotrustSystemId, xCrefotrustApiKey, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {RevokeCredentialsDto} revokeCredentialsDto 
     * @param {string} [xCrefotrustSystemId] 
     * @param {string} [xCrefotrustApiKey] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CredentialsApi
     */
    public credentialControllerRevokeCredentialsPublic(revokeCredentialsDto: RevokeCredentialsDto, xCrefotrustSystemId?: string, xCrefotrustApiKey?: string, options?: AxiosRequestConfig) {
        return CredentialsApiFp(this.configuration).credentialControllerRevokeCredentialsPublic(revokeCredentialsDto, xCrefotrustSystemId, xCrefotrustApiKey, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DIDApi - axios parameter creator
 * @export
 */
export const DIDApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        didControllerGetCrefoTrustDidDocument: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/.well-known/did.json`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} type 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        didControllerGetDidDocument: async (type: string, id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'type' is not null or undefined
            assertParamExists('didControllerGetDidDocument', 'type', type)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('didControllerGetDidDocument', 'id', id)
            const localVarPath = `/{type}/{id}/did.json`
                .replace(`{${"type"}}`, encodeURIComponent(String(type)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DIDApi - functional programming interface
 * @export
 */
export const DIDApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DIDApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async didControllerGetCrefoTrustDidDocument(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.didControllerGetCrefoTrustDidDocument(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} type 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async didControllerGetDidDocument(type: string, id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.didControllerGetDidDocument(type, id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DIDApi - factory interface
 * @export
 */
export const DIDApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DIDApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        didControllerGetCrefoTrustDidDocument(options?: any): AxiosPromise<void> {
            return localVarFp.didControllerGetCrefoTrustDidDocument(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} type 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        didControllerGetDidDocument(type: string, id: string, options?: any): AxiosPromise<void> {
            return localVarFp.didControllerGetDidDocument(type, id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DIDApi - object-oriented interface
 * @export
 * @class DIDApi
 * @extends {BaseAPI}
 */
export class DIDApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DIDApi
     */
    public didControllerGetCrefoTrustDidDocument(options?: AxiosRequestConfig) {
        return DIDApiFp(this.configuration).didControllerGetCrefoTrustDidDocument(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} type 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DIDApi
     */
    public didControllerGetDidDocument(type: string, id: string, options?: AxiosRequestConfig) {
        return DIDApiFp(this.configuration).didControllerGetDidDocument(type, id, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * FirmenwissenApi - axios parameter creator
 * @export
 */
export const FirmenwissenApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Search company by name at Firmenwissen API.
         * @param {string} companyName 
         * @param {string} [did] A unique decentralized identifier connected to the current session.
         * @param {string} [orderId] A valid identifier, created by a verifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        firmenwissenGatewayControllerSearchCompanies: async (companyName: string, did?: string, orderId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyName' is not null or undefined
            assertParamExists('firmenwissenGatewayControllerSearchCompanies', 'companyName', companyName)
            const localVarPath = `/api/firmenwissen/searchCompany/{companyName}`
                .replace(`{${"companyName"}}`, encodeURIComponent(String(companyName)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (did != null) {
                localVarHeaderParameter['did'] = String(did);
            }

            if (orderId != null) {
                localVarHeaderParameter['order-id'] = String(orderId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Search managers of a company based on the company\'s crefoId at Firmenwissen API.
         * @param {string} crefoId 
         * @param {string} [did] A unique decentralized identifier connected to the current session.
         * @param {string} [orderId] A valid identifier, created by a verifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        firmenwissenGatewayControllerSearchManager: async (crefoId: string, did?: string, orderId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'crefoId' is not null or undefined
            assertParamExists('firmenwissenGatewayControllerSearchManager', 'crefoId', crefoId)
            const localVarPath = `/api/firmenwissen/searchManager/{crefoId}`
                .replace(`{${"crefoId"}}`, encodeURIComponent(String(crefoId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (did != null) {
                localVarHeaderParameter['did'] = String(did);
            }

            if (orderId != null) {
                localVarHeaderParameter['order-id'] = String(orderId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FirmenwissenApi - functional programming interface
 * @export
 */
export const FirmenwissenApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FirmenwissenApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Search company by name at Firmenwissen API.
         * @param {string} companyName 
         * @param {string} [did] A unique decentralized identifier connected to the current session.
         * @param {string} [orderId] A valid identifier, created by a verifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async firmenwissenGatewayControllerSearchCompanies(companyName: string, did?: string, orderId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Company>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.firmenwissenGatewayControllerSearchCompanies(companyName, did, orderId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Search managers of a company based on the company\'s crefoId at Firmenwissen API.
         * @param {string} crefoId 
         * @param {string} [did] A unique decentralized identifier connected to the current session.
         * @param {string} [orderId] A valid identifier, created by a verifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async firmenwissenGatewayControllerSearchManager(crefoId: string, did?: string, orderId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Manager>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.firmenwissenGatewayControllerSearchManager(crefoId, did, orderId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * FirmenwissenApi - factory interface
 * @export
 */
export const FirmenwissenApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FirmenwissenApiFp(configuration)
    return {
        /**
         * 
         * @summary Search company by name at Firmenwissen API.
         * @param {string} companyName 
         * @param {string} [did] A unique decentralized identifier connected to the current session.
         * @param {string} [orderId] A valid identifier, created by a verifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        firmenwissenGatewayControllerSearchCompanies(companyName: string, did?: string, orderId?: string, options?: any): AxiosPromise<Array<Company>> {
            return localVarFp.firmenwissenGatewayControllerSearchCompanies(companyName, did, orderId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Search managers of a company based on the company\'s crefoId at Firmenwissen API.
         * @param {string} crefoId 
         * @param {string} [did] A unique decentralized identifier connected to the current session.
         * @param {string} [orderId] A valid identifier, created by a verifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        firmenwissenGatewayControllerSearchManager(crefoId: string, did?: string, orderId?: string, options?: any): AxiosPromise<Array<Manager>> {
            return localVarFp.firmenwissenGatewayControllerSearchManager(crefoId, did, orderId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * FirmenwissenApi - object-oriented interface
 * @export
 * @class FirmenwissenApi
 * @extends {BaseAPI}
 */
export class FirmenwissenApi extends BaseAPI {
    /**
     * 
     * @summary Search company by name at Firmenwissen API.
     * @param {string} companyName 
     * @param {string} [did] A unique decentralized identifier connected to the current session.
     * @param {string} [orderId] A valid identifier, created by a verifier.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FirmenwissenApi
     */
    public firmenwissenGatewayControllerSearchCompanies(companyName: string, did?: string, orderId?: string, options?: AxiosRequestConfig) {
        return FirmenwissenApiFp(this.configuration).firmenwissenGatewayControllerSearchCompanies(companyName, did, orderId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Search managers of a company based on the company\'s crefoId at Firmenwissen API.
     * @param {string} crefoId 
     * @param {string} [did] A unique decentralized identifier connected to the current session.
     * @param {string} [orderId] A valid identifier, created by a verifier.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FirmenwissenApi
     */
    public firmenwissenGatewayControllerSearchManager(crefoId: string, did?: string, orderId?: string, options?: AxiosRequestConfig) {
        return FirmenwissenApiFp(this.configuration).firmenwissenGatewayControllerSearchManager(crefoId, did, orderId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * HolderApi - axios parameter creator
 * @export
 */
export const HolderApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Aborts an order.
         * @param {string} did A unique decentralized identifier connected to the current session.
         * @param {string} orderId A valid identifier, created by a verifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        holderGatewayControllerAbort: async (did: string, orderId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'did' is not null or undefined
            assertParamExists('holderGatewayControllerAbort', 'did', did)
            // verify required parameter 'orderId' is not null or undefined
            assertParamExists('holderGatewayControllerAbort', 'orderId', orderId)
            const localVarPath = `/api/abort`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (did != null) {
                localVarHeaderParameter['did'] = String(did);
            }

            if (orderId != null) {
                localVarHeaderParameter['order-id'] = String(orderId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get current state of identification.
         * @param {string} did A unique decentralized identifier connected to the current session.
         * @param {string} orderId A valid identifier, created by a verifier.
         * @param {string} [xCrefotrustSystemId] System key used for internal self-service.
         * @param {string} [xCrefotrustApiKey] API key used for internal self-service.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        holderGatewayControllerDebugState: async (did: string, orderId: string, xCrefotrustSystemId?: string, xCrefotrustApiKey?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'did' is not null or undefined
            assertParamExists('holderGatewayControllerDebugState', 'did', did)
            // verify required parameter 'orderId' is not null or undefined
            assertParamExists('holderGatewayControllerDebugState', 'orderId', orderId)
            const localVarPath = `/api/identification/debug`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xCrefotrustSystemId != null) {
                localVarHeaderParameter['x-crefotrust-system-id'] = String(xCrefotrustSystemId);
            }

            if (xCrefotrustApiKey != null) {
                localVarHeaderParameter['x-crefotrust-api-key'] = String(xCrefotrustApiKey);
            }

            if (did != null) {
                localVarHeaderParameter['did'] = String(did);
            }

            if (orderId != null) {
                localVarHeaderParameter['order-id'] = String(orderId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns redirect information.
         * @param {string} orderId A valid identifier, created by a verifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        holderGatewayControllerGetOrderAuthConfig: async (orderId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orderId' is not null or undefined
            assertParamExists('holderGatewayControllerGetOrderAuthConfig', 'orderId', orderId)
            const localVarPath = `/api/redirects`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (orderId != null) {
                localVarHeaderParameter['order-id'] = String(orderId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get current state of identification.
         * @param {string} did A unique decentralized identifier connected to the current session.
         * @param {string} orderId A valid identifier, created by a verifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        holderGatewayControllerGetState: async (did: string, orderId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'did' is not null or undefined
            assertParamExists('holderGatewayControllerGetState', 'did', did)
            // verify required parameter 'orderId' is not null or undefined
            assertParamExists('holderGatewayControllerGetState', 'orderId', orderId)
            const localVarPath = `/api/identification`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (did != null) {
                localVarHeaderParameter['did'] = String(did);
            }

            if (orderId != null) {
                localVarHeaderParameter['order-id'] = String(orderId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the verifiable presentations of the JWTs of credentials.
         * @param {string} did A unique decentralized identifier connected to the current session.
         * @param {string} orderId A valid identifier, created by a verifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        holderGatewayControllerGetVerifiablePresentations: async (did: string, orderId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'did' is not null or undefined
            assertParamExists('holderGatewayControllerGetVerifiablePresentations', 'did', did)
            // verify required parameter 'orderId' is not null or undefined
            assertParamExists('holderGatewayControllerGetVerifiablePresentations', 'orderId', orderId)
            const localVarPath = `/api/verifiablePresentations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (did != null) {
                localVarHeaderParameter['did'] = String(did);
            }

            if (orderId != null) {
                localVarHeaderParameter['order-id'] = String(orderId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns the name of the verifier who created the order.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        holderGatewayControllerGetVerifierByOrderId: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('holderGatewayControllerGetVerifierByOrderId', 'id', id)
            const localVarPath = `/api/orders/{id}/verifier`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Release credentials to verifier and get redirected to verifiers page.
         * @param {string} did A unique decentralized identifier connected to the current session.
         * @param {string} orderId A valid identifier, created by a verifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        holderGatewayControllerReleaseCredentials: async (did: string, orderId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'did' is not null or undefined
            assertParamExists('holderGatewayControllerReleaseCredentials', 'did', did)
            // verify required parameter 'orderId' is not null or undefined
            assertParamExists('holderGatewayControllerReleaseCredentials', 'orderId', orderId)
            const localVarPath = `/api/releaseCredentials`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (did != null) {
                localVarHeaderParameter['did'] = String(did);
            }

            if (orderId != null) {
                localVarHeaderParameter['order-id'] = String(orderId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update identification data to process / trigger identification request.
         * @param {string} did A unique decentralized identifier connected to the current session.
         * @param {string} orderId A valid identifier, created by a verifier.
         * @param {ContextPayloadDto} contextPayloadDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        holderGatewayControllerUpdateState: async (did: string, orderId: string, contextPayloadDto: ContextPayloadDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'did' is not null or undefined
            assertParamExists('holderGatewayControllerUpdateState', 'did', did)
            // verify required parameter 'orderId' is not null or undefined
            assertParamExists('holderGatewayControllerUpdateState', 'orderId', orderId)
            // verify required parameter 'contextPayloadDto' is not null or undefined
            assertParamExists('holderGatewayControllerUpdateState', 'contextPayloadDto', contextPayloadDto)
            const localVarPath = `/api/identification`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (did != null) {
                localVarHeaderParameter['did'] = String(did);
            }

            if (orderId != null) {
                localVarHeaderParameter['order-id'] = String(orderId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(contextPayloadDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webhooksGatewayControllerWebhookPersonVerificationFinalization: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/webhooks/holder`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * HolderApi - functional programming interface
 * @export
 */
export const HolderApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = HolderApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Aborts an order.
         * @param {string} did A unique decentralized identifier connected to the current session.
         * @param {string} orderId A valid identifier, created by a verifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async holderGatewayControllerAbort(did: string, orderId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.holderGatewayControllerAbort(did, orderId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get current state of identification.
         * @param {string} did A unique decentralized identifier connected to the current session.
         * @param {string} orderId A valid identifier, created by a verifier.
         * @param {string} [xCrefotrustSystemId] System key used for internal self-service.
         * @param {string} [xCrefotrustApiKey] API key used for internal self-service.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async holderGatewayControllerDebugState(did: string, orderId: string, xCrefotrustSystemId?: string, xCrefotrustApiKey?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<HolderState>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.holderGatewayControllerDebugState(did, orderId, xCrefotrustSystemId, xCrefotrustApiKey, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns redirect information.
         * @param {string} orderId A valid identifier, created by a verifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async holderGatewayControllerGetOrderAuthConfig(orderId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrderAuthRedirects>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.holderGatewayControllerGetOrderAuthConfig(orderId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get current state of identification.
         * @param {string} did A unique decentralized identifier connected to the current session.
         * @param {string} orderId A valid identifier, created by a verifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async holderGatewayControllerGetState(did: string, orderId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<HolderState>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.holderGatewayControllerGetState(did, orderId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get the verifiable presentations of the JWTs of credentials.
         * @param {string} did A unique decentralized identifier connected to the current session.
         * @param {string} orderId A valid identifier, created by a verifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async holderGatewayControllerGetVerifiablePresentations(did: string, orderId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.holderGatewayControllerGetVerifiablePresentations(did, orderId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns the name of the verifier who created the order.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async holderGatewayControllerGetVerifierByOrderId(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.holderGatewayControllerGetVerifierByOrderId(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Release credentials to verifier and get redirected to verifiers page.
         * @param {string} did A unique decentralized identifier connected to the current session.
         * @param {string} orderId A valid identifier, created by a verifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async holderGatewayControllerReleaseCredentials(did: string, orderId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.holderGatewayControllerReleaseCredentials(did, orderId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update identification data to process / trigger identification request.
         * @param {string} did A unique decentralized identifier connected to the current session.
         * @param {string} orderId A valid identifier, created by a verifier.
         * @param {ContextPayloadDto} contextPayloadDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async holderGatewayControllerUpdateState(did: string, orderId: string, contextPayloadDto: ContextPayloadDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<HolderState>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.holderGatewayControllerUpdateState(did, orderId, contextPayloadDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async webhooksGatewayControllerWebhookPersonVerificationFinalization(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.webhooksGatewayControllerWebhookPersonVerificationFinalization(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * HolderApi - factory interface
 * @export
 */
export const HolderApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = HolderApiFp(configuration)
    return {
        /**
         * 
         * @summary Aborts an order.
         * @param {string} did A unique decentralized identifier connected to the current session.
         * @param {string} orderId A valid identifier, created by a verifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        holderGatewayControllerAbort(did: string, orderId: string, options?: any): AxiosPromise<void> {
            return localVarFp.holderGatewayControllerAbort(did, orderId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get current state of identification.
         * @param {string} did A unique decentralized identifier connected to the current session.
         * @param {string} orderId A valid identifier, created by a verifier.
         * @param {string} [xCrefotrustSystemId] System key used for internal self-service.
         * @param {string} [xCrefotrustApiKey] API key used for internal self-service.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        holderGatewayControllerDebugState(did: string, orderId: string, xCrefotrustSystemId?: string, xCrefotrustApiKey?: string, options?: any): AxiosPromise<HolderState> {
            return localVarFp.holderGatewayControllerDebugState(did, orderId, xCrefotrustSystemId, xCrefotrustApiKey, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns redirect information.
         * @param {string} orderId A valid identifier, created by a verifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        holderGatewayControllerGetOrderAuthConfig(orderId: string, options?: any): AxiosPromise<OrderAuthRedirects> {
            return localVarFp.holderGatewayControllerGetOrderAuthConfig(orderId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get current state of identification.
         * @param {string} did A unique decentralized identifier connected to the current session.
         * @param {string} orderId A valid identifier, created by a verifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        holderGatewayControllerGetState(did: string, orderId: string, options?: any): AxiosPromise<HolderState> {
            return localVarFp.holderGatewayControllerGetState(did, orderId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get the verifiable presentations of the JWTs of credentials.
         * @param {string} did A unique decentralized identifier connected to the current session.
         * @param {string} orderId A valid identifier, created by a verifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        holderGatewayControllerGetVerifiablePresentations(did: string, orderId: string, options?: any): AxiosPromise<void> {
            return localVarFp.holderGatewayControllerGetVerifiablePresentations(did, orderId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns the name of the verifier who created the order.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        holderGatewayControllerGetVerifierByOrderId(id: string, options?: any): AxiosPromise<string> {
            return localVarFp.holderGatewayControllerGetVerifierByOrderId(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Release credentials to verifier and get redirected to verifiers page.
         * @param {string} did A unique decentralized identifier connected to the current session.
         * @param {string} orderId A valid identifier, created by a verifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        holderGatewayControllerReleaseCredentials(did: string, orderId: string, options?: any): AxiosPromise<void> {
            return localVarFp.holderGatewayControllerReleaseCredentials(did, orderId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update identification data to process / trigger identification request.
         * @param {string} did A unique decentralized identifier connected to the current session.
         * @param {string} orderId A valid identifier, created by a verifier.
         * @param {ContextPayloadDto} contextPayloadDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        holderGatewayControllerUpdateState(did: string, orderId: string, contextPayloadDto: ContextPayloadDto, options?: any): AxiosPromise<HolderState> {
            return localVarFp.holderGatewayControllerUpdateState(did, orderId, contextPayloadDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webhooksGatewayControllerWebhookPersonVerificationFinalization(options?: any): AxiosPromise<void> {
            return localVarFp.webhooksGatewayControllerWebhookPersonVerificationFinalization(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * HolderApi - object-oriented interface
 * @export
 * @class HolderApi
 * @extends {BaseAPI}
 */
export class HolderApi extends BaseAPI {
    /**
     * 
     * @summary Aborts an order.
     * @param {string} did A unique decentralized identifier connected to the current session.
     * @param {string} orderId A valid identifier, created by a verifier.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HolderApi
     */
    public holderGatewayControllerAbort(did: string, orderId: string, options?: AxiosRequestConfig) {
        return HolderApiFp(this.configuration).holderGatewayControllerAbort(did, orderId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get current state of identification.
     * @param {string} did A unique decentralized identifier connected to the current session.
     * @param {string} orderId A valid identifier, created by a verifier.
     * @param {string} [xCrefotrustSystemId] System key used for internal self-service.
     * @param {string} [xCrefotrustApiKey] API key used for internal self-service.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HolderApi
     */
    public holderGatewayControllerDebugState(did: string, orderId: string, xCrefotrustSystemId?: string, xCrefotrustApiKey?: string, options?: AxiosRequestConfig) {
        return HolderApiFp(this.configuration).holderGatewayControllerDebugState(did, orderId, xCrefotrustSystemId, xCrefotrustApiKey, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns redirect information.
     * @param {string} orderId A valid identifier, created by a verifier.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HolderApi
     */
    public holderGatewayControllerGetOrderAuthConfig(orderId: string, options?: AxiosRequestConfig) {
        return HolderApiFp(this.configuration).holderGatewayControllerGetOrderAuthConfig(orderId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get current state of identification.
     * @param {string} did A unique decentralized identifier connected to the current session.
     * @param {string} orderId A valid identifier, created by a verifier.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HolderApi
     */
    public holderGatewayControllerGetState(did: string, orderId: string, options?: AxiosRequestConfig) {
        return HolderApiFp(this.configuration).holderGatewayControllerGetState(did, orderId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get the verifiable presentations of the JWTs of credentials.
     * @param {string} did A unique decentralized identifier connected to the current session.
     * @param {string} orderId A valid identifier, created by a verifier.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HolderApi
     */
    public holderGatewayControllerGetVerifiablePresentations(did: string, orderId: string, options?: AxiosRequestConfig) {
        return HolderApiFp(this.configuration).holderGatewayControllerGetVerifiablePresentations(did, orderId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns the name of the verifier who created the order.
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HolderApi
     */
    public holderGatewayControllerGetVerifierByOrderId(id: string, options?: AxiosRequestConfig) {
        return HolderApiFp(this.configuration).holderGatewayControllerGetVerifierByOrderId(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Release credentials to verifier and get redirected to verifiers page.
     * @param {string} did A unique decentralized identifier connected to the current session.
     * @param {string} orderId A valid identifier, created by a verifier.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HolderApi
     */
    public holderGatewayControllerReleaseCredentials(did: string, orderId: string, options?: AxiosRequestConfig) {
        return HolderApiFp(this.configuration).holderGatewayControllerReleaseCredentials(did, orderId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update identification data to process / trigger identification request.
     * @param {string} did A unique decentralized identifier connected to the current session.
     * @param {string} orderId A valid identifier, created by a verifier.
     * @param {ContextPayloadDto} contextPayloadDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HolderApi
     */
    public holderGatewayControllerUpdateState(did: string, orderId: string, contextPayloadDto: ContextPayloadDto, options?: AxiosRequestConfig) {
        return HolderApiFp(this.configuration).holderGatewayControllerUpdateState(did, orderId, contextPayloadDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HolderApi
     */
    public webhooksGatewayControllerWebhookPersonVerificationFinalization(options?: AxiosRequestConfig) {
        return HolderApiFp(this.configuration).webhooksGatewayControllerWebhookPersonVerificationFinalization(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * IdentProviderApi - axios parameter creator
 * @export
 */
export const IdentProviderApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webhooksGatewayController: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/webhooks/pin`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webhooksGatewayControllerWebhookPersonVerificationFinalization: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/webhooks/holder`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webhooksGatewayController_1: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/webhooks/pin`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * IdentProviderApi - functional programming interface
 * @export
 */
export const IdentProviderApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = IdentProviderApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async webhooksGatewayController(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.webhooksGatewayController(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async webhooksGatewayControllerWebhookPersonVerificationFinalization(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.webhooksGatewayControllerWebhookPersonVerificationFinalization(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async webhooksGatewayController_1(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.webhooksGatewayController_1(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * IdentProviderApi - factory interface
 * @export
 */
export const IdentProviderApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = IdentProviderApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webhooksGatewayController(options?: any): AxiosPromise<void> {
            return localVarFp.webhooksGatewayController(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webhooksGatewayControllerWebhookPersonVerificationFinalization(options?: any): AxiosPromise<void> {
            return localVarFp.webhooksGatewayControllerWebhookPersonVerificationFinalization(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webhooksGatewayController_1(options?: any): AxiosPromise<void> {
            return localVarFp.webhooksGatewayController_1(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * IdentProviderApi - object-oriented interface
 * @export
 * @class IdentProviderApi
 * @extends {BaseAPI}
 */
export class IdentProviderApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IdentProviderApi
     */
    public webhooksGatewayController(options?: AxiosRequestConfig) {
        return IdentProviderApiFp(this.configuration).webhooksGatewayController(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IdentProviderApi
     */
    public webhooksGatewayControllerWebhookPersonVerificationFinalization(options?: AxiosRequestConfig) {
        return IdentProviderApiFp(this.configuration).webhooksGatewayControllerWebhookPersonVerificationFinalization(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IdentProviderApi
     */
    public webhooksGatewayController_1(options?: AxiosRequestConfig) {
        return IdentProviderApiFp(this.configuration).webhooksGatewayController_1(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * RequestsApi - axios parameter creator
 * @export
 */
export const RequestsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} id 
         * @param {UpdateRequestDto} updateRequestDto 
         * @param {string} [xCrefotrustSystemId] System key used for internal self-service.
         * @param {string} [xCrefotrustApiKey] API key used for internal self-service.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        requestsControllerUpdateRequest: async (id: string, updateRequestDto: UpdateRequestDto, xCrefotrustSystemId?: string, xCrefotrustApiKey?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('requestsControllerUpdateRequest', 'id', id)
            // verify required parameter 'updateRequestDto' is not null or undefined
            assertParamExists('requestsControllerUpdateRequest', 'updateRequestDto', updateRequestDto)
            const localVarPath = `/api/requests/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xCrefotrustSystemId != null) {
                localVarHeaderParameter['x-crefotrust-system-id'] = String(xCrefotrustSystemId);
            }

            if (xCrefotrustApiKey != null) {
                localVarHeaderParameter['x-crefotrust-api-key'] = String(xCrefotrustApiKey);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RequestsApi - functional programming interface
 * @export
 */
export const RequestsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = RequestsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} id 
         * @param {UpdateRequestDto} updateRequestDto 
         * @param {string} [xCrefotrustSystemId] System key used for internal self-service.
         * @param {string} [xCrefotrustApiKey] API key used for internal self-service.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async requestsControllerUpdateRequest(id: string, updateRequestDto: UpdateRequestDto, xCrefotrustSystemId?: string, xCrefotrustApiKey?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RequestsControllerUpdateRequest200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.requestsControllerUpdateRequest(id, updateRequestDto, xCrefotrustSystemId, xCrefotrustApiKey, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * RequestsApi - factory interface
 * @export
 */
export const RequestsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = RequestsApiFp(configuration)
    return {
        /**
         * 
         * @param {string} id 
         * @param {UpdateRequestDto} updateRequestDto 
         * @param {string} [xCrefotrustSystemId] System key used for internal self-service.
         * @param {string} [xCrefotrustApiKey] API key used for internal self-service.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        requestsControllerUpdateRequest(id: string, updateRequestDto: UpdateRequestDto, xCrefotrustSystemId?: string, xCrefotrustApiKey?: string, options?: any): AxiosPromise<RequestsControllerUpdateRequest200Response> {
            return localVarFp.requestsControllerUpdateRequest(id, updateRequestDto, xCrefotrustSystemId, xCrefotrustApiKey, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * RequestsApi - object-oriented interface
 * @export
 * @class RequestsApi
 * @extends {BaseAPI}
 */
export class RequestsApi extends BaseAPI {
    /**
     * 
     * @param {string} id 
     * @param {UpdateRequestDto} updateRequestDto 
     * @param {string} [xCrefotrustSystemId] System key used for internal self-service.
     * @param {string} [xCrefotrustApiKey] API key used for internal self-service.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RequestsApi
     */
    public requestsControllerUpdateRequest(id: string, updateRequestDto: UpdateRequestDto, xCrefotrustSystemId?: string, xCrefotrustApiKey?: string, options?: AxiosRequestConfig) {
        return RequestsApiFp(this.configuration).requestsControllerUpdateRequest(id, updateRequestDto, xCrefotrustSystemId, xCrefotrustApiKey, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * SSOUserManagementApi - axios parameter creator
 * @export
 */
export const SSOUserManagementApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get a User from the SSO User Management API
         * @param {string} orderId A valid identifier, created by a verifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ssoUserManagementControllerIsSameCrefoId: async (orderId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orderId' is not null or undefined
            assertParamExists('ssoUserManagementControllerIsSameCrefoId', 'orderId', orderId)
            const localVarPath = `/api/user-management/person/match`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (orderId != null) {
                localVarHeaderParameter['order-id'] = String(orderId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update a persons unverifiedCrefoNummer in the SSO User Management API
         * @param {string} orderId A valid identifier, created by a verifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ssoUserManagementControllerUpdateUnverifiedCrefoId: async (orderId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orderId' is not null or undefined
            assertParamExists('ssoUserManagementControllerUpdateUnverifiedCrefoId', 'orderId', orderId)
            const localVarPath = `/api/user-management/person/crefo-id`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (orderId != null) {
                localVarHeaderParameter['order-id'] = String(orderId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SSOUserManagementApi - functional programming interface
 * @export
 */
export const SSOUserManagementApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SSOUserManagementApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get a User from the SSO User Management API
         * @param {string} orderId A valid identifier, created by a verifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async ssoUserManagementControllerIsSameCrefoId(orderId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IsSameCrefoIdResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.ssoUserManagementControllerIsSameCrefoId(orderId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update a persons unverifiedCrefoNummer in the SSO User Management API
         * @param {string} orderId A valid identifier, created by a verifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async ssoUserManagementControllerUpdateUnverifiedCrefoId(orderId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.ssoUserManagementControllerUpdateUnverifiedCrefoId(orderId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SSOUserManagementApi - factory interface
 * @export
 */
export const SSOUserManagementApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SSOUserManagementApiFp(configuration)
    return {
        /**
         * 
         * @summary Get a User from the SSO User Management API
         * @param {string} orderId A valid identifier, created by a verifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ssoUserManagementControllerIsSameCrefoId(orderId: string, options?: any): AxiosPromise<IsSameCrefoIdResponse> {
            return localVarFp.ssoUserManagementControllerIsSameCrefoId(orderId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update a persons unverifiedCrefoNummer in the SSO User Management API
         * @param {string} orderId A valid identifier, created by a verifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ssoUserManagementControllerUpdateUnverifiedCrefoId(orderId: string, options?: any): AxiosPromise<void> {
            return localVarFp.ssoUserManagementControllerUpdateUnverifiedCrefoId(orderId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SSOUserManagementApi - object-oriented interface
 * @export
 * @class SSOUserManagementApi
 * @extends {BaseAPI}
 */
export class SSOUserManagementApi extends BaseAPI {
    /**
     * 
     * @summary Get a User from the SSO User Management API
     * @param {string} orderId A valid identifier, created by a verifier.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SSOUserManagementApi
     */
    public ssoUserManagementControllerIsSameCrefoId(orderId: string, options?: AxiosRequestConfig) {
        return SSOUserManagementApiFp(this.configuration).ssoUserManagementControllerIsSameCrefoId(orderId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update a persons unverifiedCrefoNummer in the SSO User Management API
     * @param {string} orderId A valid identifier, created by a verifier.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SSOUserManagementApi
     */
    public ssoUserManagementControllerUpdateUnverifiedCrefoId(orderId: string, options?: AxiosRequestConfig) {
        return SSOUserManagementApiFp(this.configuration).ssoUserManagementControllerUpdateUnverifiedCrefoId(orderId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * SelfServiceApi - axios parameter creator
 * @export
 */
export const SelfServiceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Creates a pin order for a customer and sends the pin via mail.
         * @summary Creates a pin order for a customer and sends the pin via mail.
         * @param {CreatePinLetterDto} createPinLetterDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        selfServiceGatewayControllerCreateMailLetter: async (createPinLetterDto: CreatePinLetterDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createPinLetterDto' is not null or undefined
            assertParamExists('selfServiceGatewayControllerCreateMailLetter', 'createPinLetterDto', createPinLetterDto)
            const localVarPath = `/api/communications/mail/pin-letter`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createPinLetterDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Sends an email to the selected authorized Representative.
         * @summary Sends an email to the selected authorized Representative.
         * @param {CreateAuthRepEmailDto} createAuthRepEmailDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        selfServiceGatewayControllerSendAuthRepEmail: async (createAuthRepEmailDto: CreateAuthRepEmailDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createAuthRepEmailDto' is not null or undefined
            assertParamExists('selfServiceGatewayControllerSendAuthRepEmail', 'createAuthRepEmailDto', createAuthRepEmailDto)
            const localVarPath = `/api/communications/email/auth-rep`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createAuthRepEmailDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates certain fields of an order.
         * @param {string} id 
         * @param {UpdateOrderDto} updateOrderDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        selfServiceGatewayControllerUpdateOrder: async (id: string, updateOrderDto: UpdateOrderDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('selfServiceGatewayControllerUpdateOrder', 'id', id)
            // verify required parameter 'updateOrderDto' is not null or undefined
            assertParamExists('selfServiceGatewayControllerUpdateOrder', 'updateOrderDto', updateOrderDto)
            const localVarPath = `/api/communications/orders/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateOrderDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Sets the current state of an order.
         * @param {string} id 
         * @param {UpdateOrderStateDto} updateOrderStateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        selfServiceGatewayControllerUpdateOrderState: async (id: string, updateOrderStateDto: UpdateOrderStateDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('selfServiceGatewayControllerUpdateOrderState', 'id', id)
            // verify required parameter 'updateOrderStateDto' is not null or undefined
            assertParamExists('selfServiceGatewayControllerUpdateOrderState', 'updateOrderStateDto', updateOrderStateDto)
            const localVarPath = `/api/communications/orders/{id}/state`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateOrderStateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SelfServiceApi - functional programming interface
 * @export
 */
export const SelfServiceApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SelfServiceApiAxiosParamCreator(configuration)
    return {
        /**
         * Creates a pin order for a customer and sends the pin via mail.
         * @summary Creates a pin order for a customer and sends the pin via mail.
         * @param {CreatePinLetterDto} createPinLetterDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async selfServiceGatewayControllerCreateMailLetter(createPinLetterDto: CreatePinLetterDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SuccessResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.selfServiceGatewayControllerCreateMailLetter(createPinLetterDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Sends an email to the selected authorized Representative.
         * @summary Sends an email to the selected authorized Representative.
         * @param {CreateAuthRepEmailDto} createAuthRepEmailDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async selfServiceGatewayControllerSendAuthRepEmail(createAuthRepEmailDto: CreateAuthRepEmailDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SuccessResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.selfServiceGatewayControllerSendAuthRepEmail(createAuthRepEmailDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Updates certain fields of an order.
         * @param {string} id 
         * @param {UpdateOrderDto} updateOrderDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async selfServiceGatewayControllerUpdateOrder(id: string, updateOrderDto: UpdateOrderDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ExtOrderResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.selfServiceGatewayControllerUpdateOrder(id, updateOrderDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Sets the current state of an order.
         * @param {string} id 
         * @param {UpdateOrderStateDto} updateOrderStateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async selfServiceGatewayControllerUpdateOrderState(id: string, updateOrderStateDto: UpdateOrderStateDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ExtOrderResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.selfServiceGatewayControllerUpdateOrderState(id, updateOrderStateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SelfServiceApi - factory interface
 * @export
 */
export const SelfServiceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SelfServiceApiFp(configuration)
    return {
        /**
         * Creates a pin order for a customer and sends the pin via mail.
         * @summary Creates a pin order for a customer and sends the pin via mail.
         * @param {CreatePinLetterDto} createPinLetterDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        selfServiceGatewayControllerCreateMailLetter(createPinLetterDto: CreatePinLetterDto, options?: any): AxiosPromise<SuccessResponse> {
            return localVarFp.selfServiceGatewayControllerCreateMailLetter(createPinLetterDto, options).then((request) => request(axios, basePath));
        },
        /**
         * Sends an email to the selected authorized Representative.
         * @summary Sends an email to the selected authorized Representative.
         * @param {CreateAuthRepEmailDto} createAuthRepEmailDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        selfServiceGatewayControllerSendAuthRepEmail(createAuthRepEmailDto: CreateAuthRepEmailDto, options?: any): AxiosPromise<SuccessResponse> {
            return localVarFp.selfServiceGatewayControllerSendAuthRepEmail(createAuthRepEmailDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates certain fields of an order.
         * @param {string} id 
         * @param {UpdateOrderDto} updateOrderDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        selfServiceGatewayControllerUpdateOrder(id: string, updateOrderDto: UpdateOrderDto, options?: any): AxiosPromise<ExtOrderResponse> {
            return localVarFp.selfServiceGatewayControllerUpdateOrder(id, updateOrderDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Sets the current state of an order.
         * @param {string} id 
         * @param {UpdateOrderStateDto} updateOrderStateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        selfServiceGatewayControllerUpdateOrderState(id: string, updateOrderStateDto: UpdateOrderStateDto, options?: any): AxiosPromise<ExtOrderResponse> {
            return localVarFp.selfServiceGatewayControllerUpdateOrderState(id, updateOrderStateDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SelfServiceApi - object-oriented interface
 * @export
 * @class SelfServiceApi
 * @extends {BaseAPI}
 */
export class SelfServiceApi extends BaseAPI {
    /**
     * Creates a pin order for a customer and sends the pin via mail.
     * @summary Creates a pin order for a customer and sends the pin via mail.
     * @param {CreatePinLetterDto} createPinLetterDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SelfServiceApi
     */
    public selfServiceGatewayControllerCreateMailLetter(createPinLetterDto: CreatePinLetterDto, options?: AxiosRequestConfig) {
        return SelfServiceApiFp(this.configuration).selfServiceGatewayControllerCreateMailLetter(createPinLetterDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Sends an email to the selected authorized Representative.
     * @summary Sends an email to the selected authorized Representative.
     * @param {CreateAuthRepEmailDto} createAuthRepEmailDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SelfServiceApi
     */
    public selfServiceGatewayControllerSendAuthRepEmail(createAuthRepEmailDto: CreateAuthRepEmailDto, options?: AxiosRequestConfig) {
        return SelfServiceApiFp(this.configuration).selfServiceGatewayControllerSendAuthRepEmail(createAuthRepEmailDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Updates certain fields of an order.
     * @param {string} id 
     * @param {UpdateOrderDto} updateOrderDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SelfServiceApi
     */
    public selfServiceGatewayControllerUpdateOrder(id: string, updateOrderDto: UpdateOrderDto, options?: AxiosRequestConfig) {
        return SelfServiceApiFp(this.configuration).selfServiceGatewayControllerUpdateOrder(id, updateOrderDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Sets the current state of an order.
     * @param {string} id 
     * @param {UpdateOrderStateDto} updateOrderStateDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SelfServiceApi
     */
    public selfServiceGatewayControllerUpdateOrderState(id: string, updateOrderStateDto: UpdateOrderStateDto, options?: AxiosRequestConfig) {
        return SelfServiceApiFp(this.configuration).selfServiceGatewayControllerUpdateOrderState(id, updateOrderStateDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * VerifierApi - axios parameter creator
 * @export
 */
export const VerifierApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Creates a new order.
         * @param {CreateOrderDto} createOrderDto 
         * @param {string} [xVerifierId] Verifier id for verifiers
         * @param {string} [xCrefotrustKey] API key for verifiers
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderControllerCreate: async (createOrderDto: CreateOrderDto, xVerifierId?: string, xCrefotrustKey?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createOrderDto' is not null or undefined
            assertParamExists('orderControllerCreate', 'createOrderDto', createOrderDto)
            const localVarPath = `/api/orders`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xVerifierId != null) {
                localVarHeaderParameter['x-verifier-id'] = String(xVerifierId);
            }

            if (xCrefotrustKey != null) {
                localVarHeaderParameter['x-crefotrust-key'] = String(xCrefotrustKey);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createOrderDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns the an order based on the provided id.
         * @param {string} id 
         * @param {string} [xVerifierId] Verifier id for verifiers
         * @param {string} [xCrefotrustKey] API key for verifiers
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderControllerGetById: async (id: string, xVerifierId?: string, xCrefotrustKey?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('orderControllerGetById', 'id', id)
            const localVarPath = `/api/orders/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xVerifierId != null) {
                localVarHeaderParameter['x-verifier-id'] = String(xVerifierId);
            }

            if (xCrefotrustKey != null) {
                localVarHeaderParameter['x-crefotrust-key'] = String(xCrefotrustKey);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreateVerifierDto} createVerifierDto 
         * @param {string} [xCrefotrustSystemId] 
         * @param {string} [xCrefotrustApiKey] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        verifiersControllerCreateVerifier: async (createVerifierDto: CreateVerifierDto, xCrefotrustSystemId?: string, xCrefotrustApiKey?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createVerifierDto' is not null or undefined
            assertParamExists('verifiersControllerCreateVerifier', 'createVerifierDto', createVerifierDto)
            const localVarPath = `/api/verifiers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xCrefotrustSystemId != null) {
                localVarHeaderParameter['x-crefotrust-system-id'] = String(xCrefotrustSystemId);
            }

            if (xCrefotrustApiKey != null) {
                localVarHeaderParameter['x-crefotrust-api-key'] = String(xCrefotrustApiKey);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createVerifierDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [xCrefotrustSystemId] 
         * @param {string} [xCrefotrustApiKey] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        verifiersControllerGetVerifierById: async (id: string, xCrefotrustSystemId?: string, xCrefotrustApiKey?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('verifiersControllerGetVerifierById', 'id', id)
            const localVarPath = `/api/verifiers/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xCrefotrustSystemId != null) {
                localVarHeaderParameter['x-crefotrust-system-id'] = String(xCrefotrustSystemId);
            }

            if (xCrefotrustApiKey != null) {
                localVarHeaderParameter['x-crefotrust-api-key'] = String(xCrefotrustApiKey);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [start] 
         * @param {string} [end] 
         * @param {string} [name] 
         * @param {string} [verifierId] 
         * @param {string} [order] 
         * @param {string} [sort] 
         * @param {string} [xCrefotrustSystemId] 
         * @param {string} [xCrefotrustApiKey] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        verifiersControllerGetVerifiers: async (start?: string, end?: string, name?: string, verifierId?: string, order?: string, sort?: string, xCrefotrustSystemId?: string, xCrefotrustApiKey?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/verifiers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (start !== undefined) {
                localVarQueryParameter['start'] = start;
            }

            if (end !== undefined) {
                localVarQueryParameter['end'] = end;
            }

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (verifierId !== undefined) {
                localVarQueryParameter['verifierId'] = verifierId;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (xCrefotrustSystemId != null) {
                localVarHeaderParameter['x-crefotrust-system-id'] = String(xCrefotrustSystemId);
            }

            if (xCrefotrustApiKey != null) {
                localVarHeaderParameter['x-crefotrust-api-key'] = String(xCrefotrustApiKey);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {IsValidVerifierDto} isValidVerifierDto 
         * @param {string} [xCrefotrustSystemId] 
         * @param {string} [xCrefotrustApiKey] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        verifiersControllerIsValidVerifier: async (isValidVerifierDto: IsValidVerifierDto, xCrefotrustSystemId?: string, xCrefotrustApiKey?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'isValidVerifierDto' is not null or undefined
            assertParamExists('verifiersControllerIsValidVerifier', 'isValidVerifierDto', isValidVerifierDto)
            const localVarPath = `/api/verifiers/isValid`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xCrefotrustSystemId != null) {
                localVarHeaderParameter['x-crefotrust-system-id'] = String(xCrefotrustSystemId);
            }

            if (xCrefotrustApiKey != null) {
                localVarHeaderParameter['x-crefotrust-api-key'] = String(xCrefotrustApiKey);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(isValidVerifierDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * VerifierApi - functional programming interface
 * @export
 */
export const VerifierApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = VerifierApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Creates a new order.
         * @param {CreateOrderDto} createOrderDto 
         * @param {string} [xVerifierId] Verifier id for verifiers
         * @param {string} [xCrefotrustKey] API key for verifiers
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orderControllerCreate(createOrderDto: CreateOrderDto, xVerifierId?: string, xCrefotrustKey?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrderCreationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orderControllerCreate(createOrderDto, xVerifierId, xCrefotrustKey, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns the an order based on the provided id.
         * @param {string} id 
         * @param {string} [xVerifierId] Verifier id for verifiers
         * @param {string} [xCrefotrustKey] API key for verifiers
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orderControllerGetById(id: string, xVerifierId?: string, xCrefotrustKey?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ExtOrderResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orderControllerGetById(id, xVerifierId, xCrefotrustKey, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CreateVerifierDto} createVerifierDto 
         * @param {string} [xCrefotrustSystemId] 
         * @param {string} [xCrefotrustApiKey] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async verifiersControllerCreateVerifier(createVerifierDto: CreateVerifierDto, xCrefotrustSystemId?: string, xCrefotrustApiKey?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Verifier>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.verifiersControllerCreateVerifier(createVerifierDto, xCrefotrustSystemId, xCrefotrustApiKey, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [xCrefotrustSystemId] 
         * @param {string} [xCrefotrustApiKey] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async verifiersControllerGetVerifierById(id: string, xCrefotrustSystemId?: string, xCrefotrustApiKey?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Verifier>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.verifiersControllerGetVerifierById(id, xCrefotrustSystemId, xCrefotrustApiKey, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [start] 
         * @param {string} [end] 
         * @param {string} [name] 
         * @param {string} [verifierId] 
         * @param {string} [order] 
         * @param {string} [sort] 
         * @param {string} [xCrefotrustSystemId] 
         * @param {string} [xCrefotrustApiKey] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async verifiersControllerGetVerifiers(start?: string, end?: string, name?: string, verifierId?: string, order?: string, sort?: string, xCrefotrustSystemId?: string, xCrefotrustApiKey?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VerifierResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.verifiersControllerGetVerifiers(start, end, name, verifierId, order, sort, xCrefotrustSystemId, xCrefotrustApiKey, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {IsValidVerifierDto} isValidVerifierDto 
         * @param {string} [xCrefotrustSystemId] 
         * @param {string} [xCrefotrustApiKey] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async verifiersControllerIsValidVerifier(isValidVerifierDto: IsValidVerifierDto, xCrefotrustSystemId?: string, xCrefotrustApiKey?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.verifiersControllerIsValidVerifier(isValidVerifierDto, xCrefotrustSystemId, xCrefotrustApiKey, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * VerifierApi - factory interface
 * @export
 */
export const VerifierApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = VerifierApiFp(configuration)
    return {
        /**
         * 
         * @summary Creates a new order.
         * @param {CreateOrderDto} createOrderDto 
         * @param {string} [xVerifierId] Verifier id for verifiers
         * @param {string} [xCrefotrustKey] API key for verifiers
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderControllerCreate(createOrderDto: CreateOrderDto, xVerifierId?: string, xCrefotrustKey?: string, options?: any): AxiosPromise<OrderCreationResponse> {
            return localVarFp.orderControllerCreate(createOrderDto, xVerifierId, xCrefotrustKey, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns the an order based on the provided id.
         * @param {string} id 
         * @param {string} [xVerifierId] Verifier id for verifiers
         * @param {string} [xCrefotrustKey] API key for verifiers
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderControllerGetById(id: string, xVerifierId?: string, xCrefotrustKey?: string, options?: any): AxiosPromise<ExtOrderResponse> {
            return localVarFp.orderControllerGetById(id, xVerifierId, xCrefotrustKey, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CreateVerifierDto} createVerifierDto 
         * @param {string} [xCrefotrustSystemId] 
         * @param {string} [xCrefotrustApiKey] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        verifiersControllerCreateVerifier(createVerifierDto: CreateVerifierDto, xCrefotrustSystemId?: string, xCrefotrustApiKey?: string, options?: any): AxiosPromise<Verifier> {
            return localVarFp.verifiersControllerCreateVerifier(createVerifierDto, xCrefotrustSystemId, xCrefotrustApiKey, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [xCrefotrustSystemId] 
         * @param {string} [xCrefotrustApiKey] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        verifiersControllerGetVerifierById(id: string, xCrefotrustSystemId?: string, xCrefotrustApiKey?: string, options?: any): AxiosPromise<Verifier> {
            return localVarFp.verifiersControllerGetVerifierById(id, xCrefotrustSystemId, xCrefotrustApiKey, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [start] 
         * @param {string} [end] 
         * @param {string} [name] 
         * @param {string} [verifierId] 
         * @param {string} [order] 
         * @param {string} [sort] 
         * @param {string} [xCrefotrustSystemId] 
         * @param {string} [xCrefotrustApiKey] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        verifiersControllerGetVerifiers(start?: string, end?: string, name?: string, verifierId?: string, order?: string, sort?: string, xCrefotrustSystemId?: string, xCrefotrustApiKey?: string, options?: any): AxiosPromise<VerifierResponse> {
            return localVarFp.verifiersControllerGetVerifiers(start, end, name, verifierId, order, sort, xCrefotrustSystemId, xCrefotrustApiKey, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {IsValidVerifierDto} isValidVerifierDto 
         * @param {string} [xCrefotrustSystemId] 
         * @param {string} [xCrefotrustApiKey] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        verifiersControllerIsValidVerifier(isValidVerifierDto: IsValidVerifierDto, xCrefotrustSystemId?: string, xCrefotrustApiKey?: string, options?: any): AxiosPromise<void> {
            return localVarFp.verifiersControllerIsValidVerifier(isValidVerifierDto, xCrefotrustSystemId, xCrefotrustApiKey, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * VerifierApi - object-oriented interface
 * @export
 * @class VerifierApi
 * @extends {BaseAPI}
 */
export class VerifierApi extends BaseAPI {
    /**
     * 
     * @summary Creates a new order.
     * @param {CreateOrderDto} createOrderDto 
     * @param {string} [xVerifierId] Verifier id for verifiers
     * @param {string} [xCrefotrustKey] API key for verifiers
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VerifierApi
     */
    public orderControllerCreate(createOrderDto: CreateOrderDto, xVerifierId?: string, xCrefotrustKey?: string, options?: AxiosRequestConfig) {
        return VerifierApiFp(this.configuration).orderControllerCreate(createOrderDto, xVerifierId, xCrefotrustKey, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns the an order based on the provided id.
     * @param {string} id 
     * @param {string} [xVerifierId] Verifier id for verifiers
     * @param {string} [xCrefotrustKey] API key for verifiers
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VerifierApi
     */
    public orderControllerGetById(id: string, xVerifierId?: string, xCrefotrustKey?: string, options?: AxiosRequestConfig) {
        return VerifierApiFp(this.configuration).orderControllerGetById(id, xVerifierId, xCrefotrustKey, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CreateVerifierDto} createVerifierDto 
     * @param {string} [xCrefotrustSystemId] 
     * @param {string} [xCrefotrustApiKey] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VerifierApi
     */
    public verifiersControllerCreateVerifier(createVerifierDto: CreateVerifierDto, xCrefotrustSystemId?: string, xCrefotrustApiKey?: string, options?: AxiosRequestConfig) {
        return VerifierApiFp(this.configuration).verifiersControllerCreateVerifier(createVerifierDto, xCrefotrustSystemId, xCrefotrustApiKey, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {string} [xCrefotrustSystemId] 
     * @param {string} [xCrefotrustApiKey] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VerifierApi
     */
    public verifiersControllerGetVerifierById(id: string, xCrefotrustSystemId?: string, xCrefotrustApiKey?: string, options?: AxiosRequestConfig) {
        return VerifierApiFp(this.configuration).verifiersControllerGetVerifierById(id, xCrefotrustSystemId, xCrefotrustApiKey, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [start] 
     * @param {string} [end] 
     * @param {string} [name] 
     * @param {string} [verifierId] 
     * @param {string} [order] 
     * @param {string} [sort] 
     * @param {string} [xCrefotrustSystemId] 
     * @param {string} [xCrefotrustApiKey] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VerifierApi
     */
    public verifiersControllerGetVerifiers(start?: string, end?: string, name?: string, verifierId?: string, order?: string, sort?: string, xCrefotrustSystemId?: string, xCrefotrustApiKey?: string, options?: AxiosRequestConfig) {
        return VerifierApiFp(this.configuration).verifiersControllerGetVerifiers(start, end, name, verifierId, order, sort, xCrefotrustSystemId, xCrefotrustApiKey, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {IsValidVerifierDto} isValidVerifierDto 
     * @param {string} [xCrefotrustSystemId] 
     * @param {string} [xCrefotrustApiKey] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VerifierApi
     */
    public verifiersControllerIsValidVerifier(isValidVerifierDto: IsValidVerifierDto, xCrefotrustSystemId?: string, xCrefotrustApiKey?: string, options?: AxiosRequestConfig) {
        return VerifierApiFp(this.configuration).verifiersControllerIsValidVerifier(isValidVerifierDto, xCrefotrustSystemId, xCrefotrustApiKey, options).then((request) => request(this.axios, this.basePath));
    }
}


