import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit';

import {
  abort,
  getHolderState,
  personVerificationFinalization,
  releaseCredentials,
  updateHolderState,
  getOrderRedirects,
} from './identApi';
import {
  Company,
  ContextPayloadDto, ContextPayloadDtoAffiliationEnum,
  Credential,
  CredentialTypeEnum,
  HolderState, IdentRequestDtoSelectedIdentProviderEnum, OrderAuthRedirects,
  PersonDto, PersonRequest, PersonRequestStateEnum
} from '../../api-clients/core-service-api-react';
import {
  identSliceName,
  IdentState,
  ReduxStateStatus,
} from '../../interfaces/redux-states.interface';
import { RequestResult } from '../../interfaces/request-result.interface';
import { RequestType } from '../../interfaces/request-type.interface';
import {Signatory} from "../../interfaces/signatories";
import { getUser } from "../../utils/get-user.helper";
import {getCredential, getCredentialPayload} from "../../utils/helpers";
import { CrefoThunkConfig, RootState } from '../store';
import { updateTargetPlatform } from '../targetPlatform/targetPlatformSlice';

const initialState: IdentState = {
  status: ReduxStateStatus.INITIAL,
};

const abortError = {
  error: '10, User aborted process',
  type: 'CrefoTrust Error',
};

const verificationError = {
  error: '20, User verification failed',
  type: 'CrefoTrust Error',
};

interface TargetPlatformResult {
  url: string;
  verificationResult: string | undefined;
}

export const sendCredentials = createAsyncThunk<void, void, CrefoThunkConfig>(
  `${identSliceName}/sendCredentials`,
  async (_, thunkAPI): Promise<void> => {
    const {
      getState,
      dispatch,
    } = thunkAPI;
    const state = getState();
    const user = getUser(state?.ident?.oidcConfig?.authority, state?.ident?.oidcConfig?.client_id);
    const token = user?.access_token;
    window.location.href = await releaseCredentials(token ?? '', state);
  }
);

export const gotoTargetPlatform = createAsyncThunk<
  TargetPlatformResult,
  string | undefined,
  CrefoThunkConfig
>(
  `${identSliceName}/gotoTargetPlatform`,
  async (verificationResult, thunkAPI): Promise<TargetPlatformResult> => {
    const {
      getState,
    } = thunkAPI;

    const state = getState();
    const user = getUser(state.ident.oidcConfig?.authority, state.ident.oidcConfig?.client_id);
    const token = user?.access_token;

    // await refreshLogin(state);
    const redirectUrl = await abort(token ?? '', state);
    return {
      url: redirectUrl,
      verificationResult,
    };
  }
);

export const getRedirects = createAsyncThunk<
    OrderAuthRedirects,
    string,
    CrefoThunkConfig
>(
    `${identSliceName}/getOrderRedirects`,
    async (orderId): Promise<OrderAuthRedirects> => {
      if (!orderId) {
        console.error('No orderId provided')
        throw Error('No orderId provided');
      }
      return await getOrderRedirects(orderId);
    }
);

const getRequestPayload = (
  customerProvidedData: Record<CredentialTypeEnum, any>,
  state: RootState,
  selectedIdentProvider?: IdentRequestDtoSelectedIdentProviderEnum,
  affiliation?: ContextPayloadDtoAffiliationEnum,
): ContextPayloadDto => {
  const user = getUser(state.ident.oidcConfig?.authority, state.ident.oidcConfig?.client_id);
  if (state?.ident?.context?.flavor === 'B2B') {
    const emptyAddress = {
      streetAddress: '',
      postalCode: '',
      addressLocality: '',
      addressCountry: '',
    };

    if (selectedIdentProvider === IdentRequestDtoSelectedIdentProviderEnum.PinletterIdent) {
      return {
        identRequest: {
          selectedIdentProvider,
          selectedPerson: {
            email: user?.profile?.email ?? '',
          } as PersonDto,
        },
        customerProvidedData,
      } as ContextPayloadDto;
    }

    const personCredentials = state?.ident?.data?.personCredentials || [];
    const companyCredentials = state?.ident?.data?.companyCredentials || [];


    const { givenName, familyName } = getCredentialPayload(personCredentials, CredentialTypeEnum.Name) ?? { givenName: undefined, familyName: undefined };
    const birthdate = getCredentialPayload(personCredentials, CredentialTypeEnum.Birthdate)?.birthdate;
    const crefoId = getCredentialPayload(personCredentials, CredentialTypeEnum.CrefoId)?.crefoId;
    const address = getCredentialPayload(personCredentials, CredentialTypeEnum.Address)?.address;
    const legalName = getCredentialPayload(companyCredentials, CredentialTypeEnum.LegalName)?.legalName;
    const companyCrefoId = getCredentialPayload(companyCredentials, CredentialTypeEnum.CrefoId)?.crefoId;
    const companyAddress = getCredentialPayload(companyCredentials, CredentialTypeEnum.Address)?.address;

    const getAddress = (): {
      streetAddress: string;
      postalCode: string;
      addressLocality: string;
      addressCountry: string;
    } => {
      if (address) {
        if (!address.addressCountry) {
          return {
            ...address,
            addressCountry: ' ', // hack for itm (no country) => nect (request needs country)
          };
        }
        return address;
      }
      if (customerProvidedData?.address?.address) {
        return customerProvidedData.address.address;
      }
      if (state.input.chosenSignatory?.address) {
        return state.input.chosenSignatory.address;
      }
      return emptyAddress;
    };

    return {
      identRequest: {
        selectedIdentProvider,
        selectedPerson: {
          givenName: givenName ?? state.input.chosenSignatory?.givenName ?? '',
          familyName:
            familyName ?? state.input.chosenSignatory?.familyName ?? '',
          birthDate: birthdate ?? state.input.chosenSignatory?.birthDate ?? '',
          crefoId: crefoId ?? state.input.chosenSignatory?.crefoId ?? '',
          address: getAddress(),
          email: user?.profile?.email ?? '',
        },
        selectedCompany: {
          legalName: legalName ?? state.input.chosenCompany?.legalName ?? '',
          crefoId: companyCrefoId ?? state.input.chosenCompany?.crefoId ?? '',
          address:
            companyAddress ??
            state.input.chosenCompany?.address ??
            emptyAddress,
        },
      },
      customerProvidedData,
      affiliation,
    };
  } else {
    return {
      identRequest: {
        selectedIdentProvider,
        selectedPerson: {
          email: user?.profile?.email ?? '',
        } as PersonDto,
      },
      customerProvidedData,
    };
  }
};

export const personWebhook = createAsyncThunk<void, void, CrefoThunkConfig>(
  `${identSliceName}/personWebhook`,
  async (_, thunkAPI): Promise<void> => {
    const {
      getState,
    } = thunkAPI;
    const state = getState();
    const user = getUser(state.ident.oidcConfig?.authority, state.ident.oidcConfig?.client_id);
    const token = user?.access_token;

    await personVerificationFinalization(token ?? '', state);
  }
);

export const updateIdentState = createAsyncThunk<
  HolderState,
  {
    customerProvidedData: Record<string, any>;
    provider?: IdentRequestDtoSelectedIdentProviderEnum;
    affiliation?: ContextPayloadDtoAffiliationEnum;
  },
  CrefoThunkConfig
>(
  `${identSliceName}/updateState`,
  async (
    { customerProvidedData, provider, affiliation },
    thunkAPI
  ): Promise<HolderState> => {
    const {
      getState,
    } = thunkAPI;
    const state = getState();
    const user = getUser(state.ident.oidcConfig?.authority, state.ident.oidcConfig?.client_id);
    const token = user?.access_token;

    try {
      const payload = getRequestPayload(customerProvidedData, state, provider, affiliation);
      return await updateHolderState(
          token ?? '',
          state,
          payload,
      );
    } catch(e) {
      console.error(e);
      throw e;
    }

  }
);

export const getIdentState = createAsyncThunk<
  HolderState,
  void,
  CrefoThunkConfig
>(`${identSliceName}/getState`, async (_, thunkAPI): Promise<HolderState> => {
  const {
    getState,
    dispatch,
  } = thunkAPI;
  const state = getState();

  const user = getUser(state.ident.oidcConfig?.authority, state.ident.oidcConfig?.client_id);
  const token = user?.access_token;

  // await refreshLogin(state);

  let holderState ;
  try {
    holderState = await getHolderState(token ?? '', state);
  } catch(e) {
    console.error(e);
    throw e;
  }

  dispatch(
      updateTargetPlatform({
        orderId: holderState.context.orderId,
        verifierName: holderState.meta.verifierName,
        requiredCredentials: holderState.meta.requiredCredentials,
      })
  );

  return holderState;
});

export const identSlice: any = createSlice({
  name: identSliceName,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
        .addCase(getRedirects.pending, (state) => {
          state.status = ReduxStateStatus.LOADING;
        })
        .addCase(getRedirects.fulfilled, (state, action: PayloadAction<OrderAuthRedirects>) => {
          state.status = ReduxStateStatus.SUCCESS;
          state.redirects = action.payload;
          state.oidcConfig = {
            authority: action.payload?.authority,
            client_id: action.payload?.clientId,
            extraQueryParams: {
              prk: action.payload?.prk,
              title: action.payload?.title,
            },
            redirect_uri: action.payload?.redirectUri,
            response_mode: "fragment",
            response_type: "code",
            scope: 'openid',
          }
        })
      .addCase(getIdentState.pending, (state) => {
        state.status = ReduxStateStatus.LOADING;
      })
      .addCase(getIdentState.fulfilled, (state, action) => {
        state.context = action.payload.context;
        state.data = action.payload.data;
        state.meta = action.payload.meta;
        state.routing = action.payload.routing;
        state.status = ReduxStateStatus.SUCCESS;
      })
      .addCase(getIdentState.rejected, (state) => {
        state.status = ReduxStateStatus.ERROR;
      })
      .addCase(updateIdentState.pending, (state) => {
        state.status = ReduxStateStatus.LOADING;
      })
      .addCase(updateIdentState.fulfilled, (state, action) => {
        state.context = action.payload.context;
        state.data = action.payload.data;
        state.meta = action.payload.meta;
        state.routing = action.payload.routing;
        state.status = ReduxStateStatus.SUCCESS;
      })
      .addCase(updateIdentState.rejected, (state) => {
        state.status = ReduxStateStatus.ERROR;
      })
      .addCase(gotoTargetPlatform.pending, (state) => {
        state.status = ReduxStateStatus.LOADING;
      })
      .addCase(gotoTargetPlatform.fulfilled, (state, action) => {
        if (window.opener) {
          const { verificationResult } = action.payload;
          let data;
          if (
            verificationResult === null ||
            verificationResult === RequestResult.SUCCESS
          ) {
            data = abortError;
          } else {
            data = verificationError;
          }
          try {
            window.opener.postMessage(data, action.payload.url);
          } catch (e) {
            console.error(e);
          }
        } else {
          window.location.href = action.payload.url;
        }
      })
      .addCase(gotoTargetPlatform.rejected, (state) => {
        state.status = ReduxStateStatus.ERROR;
      })
      .addCase(sendCredentials.pending, (state) => {
        state.status = ReduxStateStatus.LOADING;
      })
      .addCase(sendCredentials.fulfilled, (state, action) => {
        state.status = ReduxStateStatus.SUCCESS;
      })
      .addCase(sendCredentials.rejected, (state) => {
        state.status = ReduxStateStatus.ERROR;
      })
      .addCase(personWebhook.pending, (state) => {
        state.status = ReduxStateStatus.LOADING;
      })
      .addCase(personWebhook.fulfilled, (state, action) => {
        state.status = ReduxStateStatus.SUCCESS;
      })
      .addCase(personWebhook.rejected, (state) => {
        state.status = ReduxStateStatus.ERROR;
      });
  },
});

export const selectDid = (state: RootState) => state?.ident?.context?.did;

export const selectHolderStateLoaded = (state: RootState) =>
  state.ident.status === ReduxStateStatus.SUCCESS &&
  state.ident?.context?.did !== '';

export const selectVerifierName = (state: RootState) => {
  return state?.targetPlatform?.verifierName || '';
};

export const selectIdent = (state: RootState) => {
  return state.ident;
};

export const selectOidcConfig = (state: RootState) => {
  return state.ident.oidcConfig;
};

export const selectFlavor = (state: RootState) => state?.ident?.context?.flavor;

export const selectPersonCredentials = (state: RootState): Credential[] =>
  state?.ident?.data?.personCredentials || [];

export const selectManagerFromPersonCredentials = (state: RootState): Signatory => {
  const credentials = selectPersonCredentials(state);
  const { givenName, familyName } = getCredentialPayload(credentials, CredentialTypeEnum.Name) ?? { givenName: '', familyName: '' };
  const birthDate = getCredentialPayload(credentials, CredentialTypeEnum.Birthdate)?.birthdate;
  const crefoId = getCredentialPayload(credentials, CredentialTypeEnum.CrefoId)?.crefoId;
  const address = getCredentialPayload(credentials, CredentialTypeEnum.Address)?.address;
  const isAuthRep = getCredential(credentials, CredentialTypeEnum.AuthorizedRepresentative);

  return {
    id: '',
    givenName,
    familyName,
    birthDate,
    crefoId,
    address,
    isAuthorizedRepresentative: !!isAuthRep,
    validSignatory: true,
  }
}

export const selectCompanyFromCompanyCredentials = (state: RootState): Company => {
  const credentials = selectCompanyCredentials(state);
  const legalName = getCredentialPayload(credentials, CredentialTypeEnum.LegalName)?.legalName;
  const legalForm = getCredentialPayload(credentials, CredentialTypeEnum.LegalForm)?.legalForm;
  const address = getCredentialPayload(credentials, CredentialTypeEnum.Address)?.address;
  const crefoId = getCredential(credentials, CredentialTypeEnum.CrefoId)?.crefoId;

  return {
    legalForm,
    legalName,
    address,
    crefoId,
  }
}

export const selectCompanyCredentials = (state: RootState): Credential[] =>
  state?.ident?.data?.companyCredentials || [];

export const selectRequiredPersonCredentialTypes = (state: RootState) =>
    state.ident?.meta?.requiredCredentials?.person;

export const selectRequiredAuthorizedRepresentative = (state: RootState) =>
    state.ident?.meta?.requiredCredentials?.authorizedRepresentative;

export const selectRequiredCompanyCredentialTypes = (state: RootState) =>
    state.ident?.meta?.requiredCredentials?.org;

export const selectAcceptedIdMethods = (state: RootState) =>
  state?.ident?.meta?.identProviders.acceptedIDMethods;

export const selectOfferedIdMethods = (state: RootState) =>
  state?.ident?.meta?.identProviders.offeredIDMethods;

export const selectRequestCustomerInputRequired = (state: RootState) =>
  state?.ident?.data?.requests.find(
    (req) =>
      req.type === RequestType.PERSON &&
      req.customerRequiredData.length > 0 &&
      req.state !== PersonRequestStateEnum.Accepted
  );

export const selectRequiredCredentialTypes = (state: RootState) =>
    state?.ident?.meta?.requiredCredentials;

export const selectRequestOpen = (state: RootState) =>
  state?.ident?.data?.requests.find(
    (req) =>
      req.type === RequestType.PERSON &&
      req.state === PersonRequestStateEnum.Open &&
      req.payload.crefoId === state.input.chosenSignatory?.crefoId
  );

export const selectRequestsOpen = (state: RootState) =>
  state?.ident?.data?.requests.filter(
    (req) =>
      req.type === RequestType.PERSON &&
      req.state === PersonRequestStateEnum.Open
  );



export const selectLatestRequest = (state: RootState) => {
  const unsorted = [...(state?.ident?.data?.requests || [])];
  const sorted = unsorted.sort((a: PersonRequest, b: PersonRequest) => +b.updatedAt - +a.updatedAt);

  return sorted[0];
};

export const selectOrderPresets = (state: RootState) =>
  state?.ident?.meta?.orderPresets;

export const selectCredentialState = (state: RootState) =>
    state?.ident?.meta?.credentialState;

export const selectError = (state: RootState) =>
  state.ident.status === ReduxStateStatus.ERROR;

export const selectCustomerDidProvideData = (state: RootState) =>
  state?.ident?.data?.requests.find(
    (req) =>
      req.state === PersonRequestStateEnum.CustomerPostprocessing &&
      req.customerProvidedData
  );

export const selectRoutes = (state: RootState) => state?.ident?.routing?.routes || [];

export const selectState = (state: RootState) => state;

export const selectAllRequiredPersonCredsAvailable = (state: RootState) => {
  const meta = state.ident.meta;

  return meta?.credentialState?.personCredentialState?.fulfilled;
};

export const identReducer = identSlice.reducer;
